@import "../../../adstyle.scss";

.cc__styledtable-container {
    .content-title {
        display: flex;
        align-items: center;
        >p{
            flex-basis: 20%;
        }
    }
    .content {
        display: flex;
        flex-direction: row;
        padding: 10px 0;
        justify-content: flex-start;
        align-items: flex-start !important;
        box-sizing: border-box;
        > .cc__input {
            flex-basis: 20%;
            margin: 5px;
        }

        > p {
            flex-basis: 20%;
            display: inline-block;
            overflow: hidden;
            text-align: left;
            padding: 5px;
            box-sizing: border-box;
            margin: 5px;
        }
        .img__change {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 7px !important;
            margin: 8px;
        }
    }
}
.cc__button-1 {
    border: none;
    padding: 17px;
    width: auto;
    margin: 0 20px;
    border-radius: 100px;
    font: $poppins-14px-bold;
    cursor: pointer;
    transition: transform 0.2s;
    &:hover {
        transform: scale(1.1);
    }
    &.cancel {
        background-color: $pink;
        color: $white;
        box-shadow: 0px 3px 10px $pinkshadow;
        padding-right: 35px;
        padding-left: 35px;
    }
    &.accept {
        background-color: transparent;
        color: $titlebrown;
    }
}

.logo__link {
    width: 70px;
    transition: transform 0.2s;
    &:hover {
        transform: scale(1.1);
    }
}

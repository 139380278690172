@import "../../styles.scss";

.searchbar_container {
  .searchbar_wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 1em 2.5em;
    border-radius: 7px;
    width: 75%;
    max-width: 1200px;
    margin: 0 auto;
    z-index: 1;
    box-shadow: 0 6px 20px rgba(0, 0, 0, 0.1);
    background-color: white;
    transform: translateY(-4em);

    .form__group {
      label {
        font-size: 14px;
        cursor: text;
        color: gray;
        font-family: "Poppins" !important;
        font-weight: bold !important;

        &.Mui-focused {
          color: $violeta;
        }
      }

      .MuiInput-underline:before {
        border-bottom: 1.5px solid rgba(0, 0, 0, 0.4);
      }

      .MuiInput-underline:after {
        border-bottom: 2px solid #eb1e70;
      }

      .MuiFormControl-fullWidth {
        margin-top: 0 !important;
      }

      input[type=text] {
        color: black;
        font-family: "Poppins";
        font-size: 16px;
      }

      button {
        box-shadow: none;

        &.MuiIconButton-root:hover {
          background-color: rgba(0, 0, 0, 0);
        }

        &:hover::after {
          display: none;
        }

        &::after {
          display: none;
        }
      }
    }

    >form {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      align-items: center;
      align-content: center;
      flex-wrap: nowrap;
      flex-grow: 1;
      padding-left: 30px;
      margin: 0 !important;

      .tiny {
        display: flex;
        justify-content: space-between;
      }

      @media screen and(min-width: 644px) {
        .form__group {
          position: relative;
          box-sizing: border-box;
          padding: 15px 5px;
          margin: 0 1em;
          margin-top: 10px;
          width: initial;
          font-family: "Poppins" !important;
          font-weight: bold !important;
          flex-grow: 1;

          .form__field {
            font-family: inherit;
            width: 100%;
            border: 0;
            border-bottom: 1px solid gray;
            outline: 0;
            font-size: 1.3rem;
            color: white;
            padding: 7px 0;
            background: transparent;
            transition: border-color 0.2s;
            color: black;
            font-family: "Poppins";
            font-size: 16px;

            &::placeholder {
              color: transparent;
            }

            &:placeholder-shown~.form__label {
              font-size: 14px;
              font-family: "Poppins";
              cursor: text;
              top: 20px;
            }

            &:focus {
              ~.form__label {
                position: absolute;
                top: 0;
                display: block;
                transition: 0.2s;
                font-size: 1rem;
                color: $violeta;
                font-weight: 700;
              }

              padding-bottom: 6px;
              font-weight: 700;
              border-bottom: 1px solid $violeta;
            }

            &:required,
            &:invalid {
              box-shadow: none;
            }
          }

          .form__label {
            position: absolute;
            top: 0;
            display: block;
            transition: 0.2s;
            font-size: 1rem;
            margin: 0 5px;
            color: gray;
            font-family: "Poppins" !important;
            font-weight: bold !important;
          }
        }
      }

      .form__button-container {
        min-width: 40px !important;
        height: 40px;
        background-color: $violeta;
        border-radius: 100px;
        display: flex;
        flex-direction: column;
        align-content: center;
        justify-content: center;
        box-shadow: 0 3px 10px rgba(138, 0, 69, 0.42);
        border: 1px solid $violeta;
        transition: transform 0.3s;

        &:hover {
          transform: scale(1.1);
        }

        .form__button {
          margin-left: 3px;
          display: block;
        }
      }
    }

    .searchbar_intro {
      text-align: left;
      color: $violeta;
      display: block;
      font-size: 1.17em;
      margin-top: 1em;
      margin-bottom: 1em;
      margin-left: 0;
      margin-right: 0;
      font-weight: bold;
    }

    @media only screen and (min-width: 645px) and (max-width: 1250px) {
      width: 90%;

      background-color: rgba(255, 255, 255, 0.6);
      backdrop-filter: blur(10px);
      flex-direction: column;

      form {
        padding: 0;
      }

      .searchbar_intro {
        margin-bottom: 0;

        br {
          display: none;
        }
      }
    }
  }
}

@media only screen and (max-width: 644px) {
  .searchbar_container {
    box-sizing: border-box;
    padding: 0;
    margin: 1em 0 !important;

    .searchbar_wrapper {
      padding: 20px;
      width: 100%;
      box-sizing: border-box;
      flex-direction: column;

      h3 {
        font-size: 20px;

        br {
          display: none;
        }
      }

      form {
        padding-left: 0;
        flex-direction: column !important;
        padding-bottom: 40px;

        .tiny {
          width: 100%;
          display: flex;
          justify-content: space-between;
          box-sizing: border-box;

          .form__group {
            &:nth-child(1) {
              margin-right: 10px;
            }

            &:nth-child(2) {
              margin-left: 10px;
            }
          }

          // &:nth-child(1) {
          //   padding-right: 100px;
          // }
        }

        .form__group {
          width: 100%;
          display: flex;
          flex-direction: column;
          box-sizing: border-box;

          .form__label {
            order: 1;
            text-align: left;
            margin-left: 15px;
            margin-bottom: 5px;
            font-family: "Poppins";
            font-size: 12px;
            color: $smoothbrown;
            margin-top: 20px;
          }

          .form__field {
            box-sizing: border-box;
            order: 2;
            width: 100%;
            padding: 10px 10px;
            border-radius: 100px;
            border: none;
            background-color: rgba(201, 197, 195, 0.3);
          }
        }

        .form__button-container {
          position: absolute;
          bottom: -30px;
          right: 30px;
          height: 60px;
          width: 60px;
        }
      }
    }
  }

  .get_programs {
    color: $violeta !important;
    overflow: hidden;
    width: 80px;
    height: 80px;
    font-size: 1px;
    border-radius: 100%;
    position: relative;

    &::after {
      position: absolute;
      content: "";
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 50px;
      font-weight: 900;
      color: $white;
      background-color: $white;
      border-radius: 10px;
      width: 50px;
      height: 4px;
    }

    &::before {
      position: absolute;
      content: "";
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 50px;
      font-weight: 900;
      color: $white;
      background-color: $white;
      border-radius: 10px;
      width: 4px;
      height: 50px;
    }
  }
}
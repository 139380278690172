.program__container {
    .sliderbanne__container {
        img {
            display: none !important;
        }

        background: url('../images/header/header_program_inside.png') no-repeat;
        background-size: cover;
    }

    //Information Block
    .program__info-container {
        .program__info-data {
            color: $program_info-data-p-color !important;
        }
    }

    //Icon Stamp Certificates
    .lois {
        img {
            margin: 0 37.8px 0 0;
            max-height: 83px;
            width: auto;
        }
    }
}
@import "../../../adstyle.scss";

.cc__header-title {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 100%;
    box-sizing: border-box;
    padding: 0 40px;
}

.orden__coments {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    > div {
        // flex-basis: 40%;
        width: 40%;
        min-width: 200px;
    }
}

.cc__modal {
    &-container {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        background-color: rgba(0, 0, 0, 0.5);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    &-info {
        width: 100%;
        max-width: 600px;
        border-radius: 20px;
        box-sizing: border-box;
        padding: 30px;
        background-color: $bodyColor;
    }
}

.comment-button {
    &-add {
        background-color: $pink;
        border-radius: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        box-sizing: border-box;
        padding: 2px 10px;
        transition: transform 0.2s;
        p {
            margin-left: 10px;
            margin-right: 10px;
        }
        img {
            margin: 0 10px;
        }
        cursor: pointer;
        &:hover {
            transform: scale(1.1);
        }
    }

    &-edit {
        background-color: $white;
        margin: 0 10px;
        border-radius: 50px;
        transition: transform 0.2s;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 2px 10px;
        cursor: pointer;
        p {
            display: block;
            // margin-right: 20px;
            // margin-left: 10px;
            // margin: 0 20px 0 10px;
            padding: 15px 20px;
            margin: 0;
            height: 100%;
        }

        img {
            padding: 10px 10px;
            transition: transform 0.2s;
            border-radius: 50px;
            &:hover {
                background-color: $buttongray;
                transform: scale(1.2);
            }
        }
        &:hover {
            transform: scale(1.1);
        }
    }
}

.cc__comments {
    display: flex;
    flex-direction: row;
    justify-content: left;
    flex-wrap: wrap;
    align-items: center;
}

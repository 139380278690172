@import "../../adstyle.scss";

.cc__login-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 94vh;
    background-color: #cac6c480;
    width: 100%;
    > form {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100%;
        background-color: #eeeceb;
        width: 50%;
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        padding: 0 40px;
        >h1 {
            margin-bottom: 100px;
            font-size: 70px;
            font-family: Poppins, goudy-old-style, serif;
            color: $titlebrown;
        }
        > label {
            text-align: left;
            width: 100%;
            margin-left: 10px;
            margin-bottom: 10px;
        }
        > input {
            border-radius: 33px;
            outline: none;
            border: 1px solid $white;
            padding: 13px;
            background-color: $white;
            display: inline-block;
            font: $poppins-14px-bold;
            color: $textgray;
            margin-bottom: 15px;
            width: 100%;
            &:focus {
                border: 1px solid $pink;
            }
        }
        > button {
            margin-top: 50px;
            padding: 15px 70px;
            &::after,
            &::before {
                display: none !important;
                visibility: hidden;
            }
        }
        >p{
            font-size: 12px;
            font-family: "Poppins";
            font-weight: bold;
        }
        .logo-login {
            margin-top: 50px;
            width: 75px;
        }
    }
}

@import "../styles.scss";
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

.sslider__container {
  width: 100%;
  margin-top: 10%;

  .slide_subcontainer {
    width: 100%;
    .slide {
      height: 80vh;
    }

    .slide_text {
      bottom: 0;
      min-height: 80px;
      left: 0;
      position: absolute;
      width: 100%;
      z-index: 2;
    }
  }
}

.slider-arrow {
  position: absolute;
  z-index: 1;
  height: 80%;
  width: 100%;
  display: flex;
  align-items: center;
}

.arrow-btn {
  height: 30px;
  width: 30px;
  background-color: white;
  border-radius: 50%;
  //display: inline-block;
  align-items: center;

  img {
    width: 9px;
    height: 17px;

    &.right {
      margin: auto;
      margin-top: 20%;
    }

    &.left {
      margin: auto;
      margin-top: 20%;
    }
  }
}

.prev {
  float: left;
  margin-left: 20px;
}

.next {
  margin-left: auto;
  margin-right: 20px;;
  float: right;
}

.slide {
  height: 80vh;
  position: relative;

  .slide_image {
    width: 100%;
    height: 80vh;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    overflow: hidden;
  }

  .slide_text {
    background-color: rgba(101, 80, 66, 0.5);
    position: absolute;
    font-size: 32px;
    color: white;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-family: "Poppins" !important;
    font-weight: bold;
    width: 40%;
    border-radius: 2px;
  }
}




.home__message {
  background-color: white;
  width: 100%;
  box-sizing: border-box;
  z-index: 2;
  margin-top: 100px auto !important;

  h1 {
    padding: 50px 150px;
    //color: #65504270;
    color: rgba(101, 80, 66, 0.4);

    text-align: right;
    font-size: 58px;
    position: relative;
    margin: 0 100px;

    img {
      position: absolute;
      top: 0;
      left: 10px;
    }
  }
}

.cc_masonry-container {
  margin: 0px auto;
  width: 100%;
  box-sizing: border-box;
  display: grid;
  // max-height: 100vh;
  max-width: 1200px;
  grid-template-columns: repeat(4, 1fr);
  position: relative;

  @media only screen and (max-width: 600px) {
    display: inline;
    grid-template-columns: 1fr;
    min-height: 322px;
  }

  .cc_masonry-grid {
    position: relative;
    overflow: hidden;
    cursor: pointer;
    box-sizing: border-box;
    margin: 2px;
    min-height: 308px;
    @media only screen and (max-width: 600px) {
      padding: 20px;
      margin: 20px;
      display: block;
      overflow: visible;
      min-height: 322px;
    }

    .card__info-container {
      transition: all 0.2s ease;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0);
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      h3 {
        margin-bottom: 0;
        font-family: "Poppins" !important;
        font-weight: bold;
      }

      h3 {
        display: none;
      }

      p {
        //background-color: rgba(0, 0, 0, 0.7);
        color: rgba(255, 255, 255, 1);
        font-weight: bold;
        img {
          display: none;
        }
      }

      @media only screen and (max-width: 600px) {
        bottom: 0;
        top: auto;
        height: 100px;
      }
    }
    @media only screen and (max-width: 600px) {
      .card__info-container {
        h3,
        p {
          color: rgba(255, 255, 255, 1);
          width: 90%;
          text-align: left;
        }
        p {
          margin-top: 2px;
          img {
            display: inline-block;
            margin-right: 5px;
          }
        }
      }
    }
    @media only screen and (min-width: 600px) {
      &:hover {
        .card__info-container {
          background-color: rgba(0, 0, 0, 0.5);
          h3,
          p {
            display: block;
            color: rgba(255, 255, 255, 1);
            background-color: transparent;
          }
        }
      }
    }

    &.items {
      &:nth-child(2) {
        grid-column: span 2;
        grid-row: span 2;
        z-index: 1;
      }

      &:nth-child(3) {
        grid-row: span 2;
        z-index: 1;
      }

      &:nth-child(5) {
        grid-column: span 2;
        z-index: 1;
      }
    }
    &.item-3 {
      &:nth-child(1) {
        grid-column: span 2;
        grid-row: span 2;
        z-index: 1;
      }

      &:nth-child(2) {
        grid-column: span 2;
        z-index: 1;
      }

      &:nth-child(3) {
        grid-row: span 2;
        z-index: 1;
      }
    }
    &.item-2 {
      &:nth-child(2) {
        grid-column: span 2;
        grid-row: span 2;
        z-index: 1;
      }

      &:nth-child(1) {
        grid-column: span 2;
        grid-row: span 2;
        z-index: 1;
      }
    }
    &.item-1 {
      &:nth-child(1) {
        grid-column: span 4;
        grid-row: span 1;
        z-index: 1;
        max-height: 308px;
      }
    }

    @media only screen and (max-width: 600px) {
      grid-column: span 1 !important;
      grid-row: span 1 !important;
      // padding: 5px 20px;
    }

    figure {
      position: absolute;
      width: 100%;
      box-sizing: border-box;
      height: 100%;
      top: 0;
      left: 0;
      margin: 0;

      @media only screen and (max-width: 600px) {
        display: block;
        height: 100%;
      }

      img {
        object-fit: cover;
        width: 100%;
        height: 100%;

        @media only screen and (max-width: 600px) {
          object-fit: cover;
          height: 100%;
        }
      }
    }
  }
}

.get_programs {
  outline: none;
  cursor: pointer;
  border-radius: 50px;
  background-color: #eb1a71;
  color: white;
  padding: 15px 40px;
  border: none;
  margin-top: 20px;
  align-self: flex-end;
  transition: color 0.3s, transform 0.3s;
  box-shadow: 0 3px 10px hsla(330, 100%, 27%, 0.42);
  border: 1px solid #eb1a71;
  font-family: "Poppins";
  font-weight: bold;

  &:hover {
    transform: scale(1.1);

    background-color: white;
    color: #eb1a71;
    &::after, &::before{
      background-color: #eb1a71;
    }
  }

  &.spaced {
    margin-bottom: 30px;
  }
}

.current_projects_container {
  margin: 0px 9%;
  .current_projects-wrapper {
    width: 100%;
    background-color: $lowgray;
    border-radius: 7px;

    h1 {
      margin-left: 30px;
    }

    .slider__container .slider__carousel .slider__carousel-wrapper {
      a {
        .card__container {
          .card__info-container {
          }
        }
      }
    }

    &.partners {
      .slider__container .slider__carousel .slider__carousel-wrapper {
        a {
          cursor: default !important;

          .card__container {
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: default !important;
          }
        }
      }
    }
  }
}


.ventajas__container {
  position: relative;
  box-sizing: border-box;
  margin-top: 200px;
  margin-bottom: 200px;

  p {
    color: #707070;
  }

  .img-ses {
    top: 0;
    right: 0;
    position: absolute;

    > img {
      z-index: 2;
    }
  }

  .ventajas__info-container {
    z-index: 3;
    box-sizing: border-box;
    margin: 0 15%;
    padding: 50px 0;
    border-radius: 7px;
    background-color: rgba(238, 236, 235, 0.8);

    > h1 {
      text-align: right;
      margin: 50px 100px;
      font-size: 64px;
    }

    > p {
      text-align: center;
    }

    .ventajas__items-container {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;

      .ventajas__item-container {
        display: flex;
        flex-direction: column;
        // justify-content: center;
        align-items: center;
        margin: 10px auto;
        box-sizing: border-box;
        width: 270px;

        > img {
          height: 60px;
        }
      }
    }
  }
}

.comments__container {
  width: 100%;
  align-items: center;
  justify-content: center;
  margin: auto;
  background-color: rgba(238, 236, 235, 0.8);
  height: 600px;

  .text__slider {
    top: 0;
    border: 2px green;
    background-color: red;
  }
}

.experience__container {
  margin-top: 200px;
  padding: 80px 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(238, 236, 235, 0.8);
  overflow: hidden;

  .parallax-outer {
    width: 70%;

    .parallax-inner {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column !important;
      margin-top: 80px;

      .experience__butons {
        margin-top: 60px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        img {
          padding: 10px;
          cursor: pointer;
          transition: transform 0.3s;

          &:hover {
            transform: scale(1.2);
          }
        }
      }
    }
  }

  .experience__container-border {
    width: 600px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    > span {
      margin-top: 30px;
    }
  }
}

.form__container {
  width: 100%;
  margin-top: 100px;

  .form__wrapper {
    margin: 0 auto;
    background-color: rgba(238, 236, 235, 0.8);
    // box-sizing: border-box;
    margin: 50px 150px;
    border-radius: 7px;

    .parallax-inner {
      padding: 10px 30%;

      > p,
      > h1 {
        text-align: left;
      }

      > form {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        > label {
          width: 100%;
          text-align: left;
          margin-top: 20px;

          > input[type="text"],
          textarea {
            box-sizing: border-box;
            width: 100%;
            padding: 10px;
            resize: none;
            border-radius: 7px;
            border: 1px solid #eeeceb;
          }

          textarea {
            height: 295px;
          }
        }

        .form__button {
          display: flex;
          flex-direction: row;
          width: 100%;
          justify-content: flex-end;

          .cancel {
            margin-left: 30px;
            outline: none;
            cursor: pointer;
            border-radius: 50px;

            background-color: white;
            color: #eb1a71;
            border: 1px solid #eb1a71;

            padding: 15px 40px;
            border: none;
            margin-top: 20px;
            align-self: flex-end;
            transition: color 0.3s, transform 0.3s;
            box-shadow: 0 3px 10px hsla(330, 100%, 27%, 0.42);

            &:hover {
              transform: scale(1.1);
              background-color: #eb1a71;
              color: white;
            }
          }

          .send {
            margin-left: 30px;
            outline: none;
            cursor: pointer;
            border-radius: 50px;
            background-color: #eb1a71;
            color: white;
            padding: 15px 40px;
            border: none;
            margin-top: 20px;
            align-self: flex-end;
            transition: color 0.3s, transform 0.3s;
            box-shadow: 0 3px 10px hsla(330, 100%, 27%, 0.42);

            &:hover {
              transform: scale(1.1);

              background-color: white;
              color: #eb1a71;
              border: 1px solid #eb1a71;
            }
          }
        }
      }
    }
  }
}

.social {
  display: none;
  margin-top: 100px;

  @media only screen and (max-width: 1250px) {
    display: block;
  }

  a {
    img {
      padding: 30px;
      transition: transform 0.3s;

      &:hover {
        transform: scale(1.2);
      }
    }
  }
}

@media only screen and (min-width: 645px) and (max-width: 1250px) {
  .home__message {
    background-color: white;
    width: 100%;
    box-sizing: border-box;
    overflow-x: hidden;
    z-index: 2;
    padding: 0 50px;
    margin-top: 100px;

    h1 {
      padding: 50px 40px;
      margin: 20px auto;
      color: #65504270;
      text-align: right;
      font-size: 31px;
      letter-spacing: -2px;
    }
  }

  .ventajas__info-container {
    margin: 0 5%  !important;

    h1 {
      margin: 50px 80px 50px 20px !important;
    }

    .ventajas__items-container {
      grid-template-columns: 1fr 1fr !important;
    }
  }
}

@media only screen and (max-width: 644px) {
  .home {
    width: 100% !important;

    .sliderbanne__container {
      min-height: 60vh;
    }
  }

  .home__message {
    background-color: white;
    width: 100% !important;
    box-sizing: border-box;
    padding: 40px;
    overflow-x: hidden;
    z-index: 2;
    margin-top: 100px;
    margin: 0 auto !important;
    h1 {
      display: block;
      margin-left: 20px;
      padding: 50px 40px;
      margin: 0 !important;
      color: rgba(101, 80, 66, 0.4) !important;
      text-align: right;
      font-size: 24px;
    }
  }

  .ventajas__container {
    position: relative;
    box-sizing: border-box;
    margin-top: 200px;

    .img-ses {
      top: 0;
      right: 0;
      position: absolute;

      > img {
        z-index: 2;
      }
    }

    .ventajas__info-container {
      z-index: 3;
      box-sizing: border-box;
      margin: 0 10px;
      padding: 50px 0;
      border-radius: 7px;
      background-color: rgba(238, 236, 235, 0.8);
      margin-top: 100px;

      > h1 {
        text-align: right;
        margin: 50px 100px;
        font-size: 64px;
      }

      > p {
        text-align: center;
      }

      .ventajas__items-container {
        display: grid;
        grid-template-columns: 1fr;

        .ventajas__item-container {
          display: flex;
          flex-direction: column;
          // justify-content: center;
          align-items: center;
          margin: 10px auto;
          box-sizing: border-box;
          width: 270px;

          > img {
            height: 60px;
          }
        }
      }
    }
  }

  .form__container {
    width: 100%;
    margin-top: 100px;

    .form__wrapper {
      margin: 0 auto;
      background-color: rgba(238, 236, 235, 0.8);
      // box-sizing: border-box;
      margin: 50px 20px;
      border-radius: 7px;

      .parallax-inner {
        padding: 10px 25px;

        > p,
        > h1 {
          text-align: left;
        }

        > form {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          > label {
            width: 100%;
            text-align: left;
            margin-top: 20px;

            > input[type="text"],
            textarea {
              box-sizing: border-box;
              width: 100%;
              padding: 10px;
              resize: none;
              border-radius: 7px;
              border: 1px solid #eeeceb;
            }

            textarea {
              height: 295px;
            }
          }

          .form__button {
            display: flex;
            flex-direction: row;
            width: 100%;
            justify-content: flex-end;

            .cancel {
              margin-left: 30px;
              outline: none;
              cursor: pointer;
              border-radius: 50px;

              background-color: white;
              color: #eb1a71;
              border: 1px solid #eb1a71;

              padding: 15px 40px;
              border: none;
              margin-top: 20px;
              align-self: flex-end;
              transition: color 0.3s, transform 0.3s;
              box-shadow: 0 3px 10px hsla(330, 100%, 27%, 0.42);

              &:hover {
                transform: scale(1.1);
                background-color: #eb1a71;
                color: white;
              }
            }

            .send {
              margin-left: 30px;
              outline: none;
              cursor: pointer;
              border-radius: 50px;
              background-color: #eb1a71;
              color: white;
              padding: 15px 40px;
              border: none;
              margin-top: 20px;
              align-self: flex-end;
              transition: color 0.3s, transform 0.3s;
              box-shadow: 0 3px 10px hsla(330, 100%, 27%, 0.42);

              &:hover {
                transform: scale(1.1);

                background-color: white;
                color: #eb1a71;
                border: 1px solid #eb1a71;
              }
            }
          }
        }
      }
    }
  }

  .current_projects {
    margin-top: 100px;
    width: 100%;
    box-sizing: border-box;
    position: relative;
    display: flex;
    height: 120vh;
    min-height: 866px;
    justify-content: center;
    align-items: center;

    .current_projects-decorative {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 50%;
      background-color: $lowgraya;
      border-radius: 7px;
    }

    .current_projects-wrapper {
      width: 100%;
      margin: 50px 0;
      padding: 50px 10px;
      background-color: $lowgray;
      border-radius: 7px;

      .slider__carousel-wrapper {
        .card__container {
          .card__img {
            height: -webkit-fill-available;
          }
        }
      }
    }
  }

  .experience__container {
    .experience__container-border {
      width: 100%;
    }

    .parallax-inner {
      width: 100%;
      display: flex;
      flex-direction: column !important;

      .experience__butons {
        margin-top: 60px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        img {
          padding: 10px;
        }
      }
    }
  }
}

.card__info__title {
  font-family: "Poppins" !important;
  font-weight: bold;
}

.comments_slider {
  height: 20%;
}

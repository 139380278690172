.cc__wraped {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    .people_card {
        width: 230px;
        height: 300px;
        position: relative;

        > img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        .bottom-text {
            background-color: rgba(0, 0, 0, 0.5);
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            > h3 {
                color: white;
            }
            p {
                margin: 0 auto;
            }
        }
    }
}

@import "../../../adstyle.scss";

.cc__modal {
    &-container {
        z-index: 30;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        background-color: rgba(0, 0, 0, 0.5);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    &-info {
        width: 100%;
        max-width: 600px;
        border-radius: 20px;
        box-sizing: border-box;
        padding: 30px;
        background-color: $bodyColor;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
}

.cc__row-certificado {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: row;
    flex-wrap: wrap;
}

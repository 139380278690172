@import "../../styles.scss";

.slider__conatiner-f {
  .slider__buttons {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 20px;
    > img {
      margin: 0 10px;
      cursor: pointer;
      transition: transform 0.2s;
      &:hover {
        transform: scale(1.1);
      }
    }
  }
  .desktop {
    display: none;
    opacity: 0;
  }
}

@media only screen and (max-width: 600px) {
  .slider__conatiner-f {
    .slider__buttons {
      display: flex;
      justify-content: center;
      align-items: center;
      justify-content: center;
      margin-top: 40px;
    }
    .desktop {
      opacity: 100%;
    }
  }
}
.slider__container {
  width: 100%;
  box-sizing: border-box;
  position: relative;
  //   overflow: hidden;
  .slider__carousel {
    display: flex;
    flex-direction: column;
    justify-content: center;
    box-sizing: border-box;
    margin: 40px auto;
    max-width: 200px;
    height: 222px;
    position: relative;
    .slider__carousel-wrapper {
      box-sizing: border-box;
      display: flex;
      position: absolute;
      flex-direction: row;
      transition: transform 0.4s;
      .slide-more {
        min-width: 310px !important;
        min-height: 225px !important;
        background-color: white;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        box-shadow: 0 4px 24px rgba(0, 0, 0, 0.26);
        box-sizing: border-box;
        transition: transform 0.3s;
        padding-top: 50px;
        margin: 0 20px;
        p {
          font-size: 18px;
        }
        &:hover {
          transform: scale(1.05);
          z-index: 3;
        }
      }
    }
  }
  .slider__buttons {
    margin: 50px 0;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: flex-end;
    align-items: center;
    box-sizing: border-box;
    > img {
      padding: 0 10px;
      cursor: pointer;
      transition: transform 0.2s;
      &:hover {
        transform: scale(1.3);
      }
    }
  }

  .slider__modal {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 100vh;
    background-origin: 4px solid green;

    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 5;
    > img {
      padding: 5%;
      max-width: 70%;
      max-height: 80vh;
    }
  }
}

@media only screen and (max-width: 600px) {
  .slider__container {
    width: 100%;
    overflow: hidden;
    padding: 0 !important;
    margin: 0 !important;
    // display: none;
    .slider__carousel {
      padding: 0 !important;
      margin: 0 !important;
      max-width: 100% !important;
    }
    .slider__buttons {
      justify-content: center;
    }
    .desktop {
      display: block;
      opacity: 100%;
    }
  }
}

.slider__conatiner-f {
  margin-bottom: 100px;
  padding: 0;
  height: auto;
  .flickity-viewport {
    position: relative;
    overflow: visible;
  }

  .flickity-button {
    background: transparent;
  }

  .flickity-prev-next-button {
    top: 120%;
    position: absolute;
    background-color: none;
    transition: transform 0.3s;
    &.next {
      transform: scale(1.5);
      &:hover {
        transform: scale(1.7);
      }
    }
    &.previous {
      transform: scale(1);
      left: auto;
      right: 70px;
      &:hover {
        transform: scale(1.2);
      }
    }
  }
  .slide-more {
    min-width: 310px !important;
    min-height: 225px !important;
    background-color: white;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: 0 4px 24px rgba(0, 0, 0, 0.26);
    box-sizing: border-box;
    transition: transform 0.3s;
    padding-top: 50px;
    margin: 0 20px;
    p {
      font-size: 18px;
    }
    &:hover {
      transform: scale(1.05);
      z-index: 3;
    }
  }
}

@import "../../adstyle.scss";

.styledFileUpload {
    > h1 {
        font: $label-font-poppins-bold;
        margin-left: 10px;
    }

    .styled__inner-wrapper {
        display: flex;
        flex-direction: row;
        .styled__info {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
            margin-left: 20px;
            &-src {
                &:nth-child(2) {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    margin: 10px 5px;
                    label {
                        margin-bottom: 10px;
                        margin-left: 5px;
                    }
                }
                &:nth-child(1) {
                    // background-color: red;
                    display: flex;
                    align-items: flex-start;
                    flex-direction: row;
                    justify-content: flex-start;
                    p,
                    label {
                        padding: 0;
                        margin: 0 10px;
                    }
                }
            }
        }

        .styled__upload-preview {
            width: 300px;
            height: 137px;
            background-size: cover;
            border-radius: 25px;
            opacity: 0.42;
            border: 1px solid $buttongray;
        }

        .styled__upload-button {
            background-color: $white;
            border-radius: 30px;
            border: 1px solid $white;
            font: $poppins-14px-regular;
            color: $buttongray;
            padding: 10px;
            cursor: pointer;
        }
    }
}

.styledFileUpload_short {
    > h1 {
        font: $label-font-poppins-bold;
        margin-left: 10px;
    }

    .short {
        display: none;
    }

    .styled__inner-wrapper {
        display: flex;
        flex-direction: column;
        .styled__info {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
            // margin-left: 20px;
            .short {
                display: none !important;
            }
            &-src {
                width: 100%;
                display: flex;
                justify-content: center;
                margin-top: 19px;
            }
        }

        .styled__upload-preview {
            width: 100%;
            height: 137px;
            background-size: cover;
            border-radius: 25px;
            opacity: 0.42;
            border: 1px solid $buttongray;
        }

        .styled__upload-button {
            background-color: $white;
            border-radius: 30px;
            border: 1px solid $white;
            font: $poppins-14px-regular;
            color: $buttongray;
            padding: 10px;
            cursor: pointer;
        }
    }
}

@import "../../../adstyle.scss";

.cc__styledtable-container {
    > .content {
        display: flex;
        flex-direction: row;
        padding: 10px 0;
        box-sizing: border-box;
        align-items: center;
        > input,
        > select {
            border: none;
            padding: 5px;
            box-sizing: border-box;
            flex-basis: 20%;
            margin: 5px;
            display: inline-block;
            background-color: transparent;
            font: $label-font-poppins-bold;
            color: $titlebrown;
        }

        > p {
            flex-basis: 20%;
            display: inline-block;
            overflow: hidden;
            text-align: left;
            padding: 5px;
            box-sizing: border-box;
            margin: 5px;
        }
    }
}
.cc__button-1 {
    border: none;
    padding: 17px;
    width: auto;
    margin: 0 20px;
    border-radius: 100px;
    font: $poppins-14px-bold;
    cursor: pointer;
    transition: transform 0.2s;
    &:hover {
        transform: scale(1.1);
    }
    &.cancel {
        background-color: $pink;
        color: $white;
        box-shadow: 0px 3px 10px $pinkshadow;
        padding-right: 35px;
        padding-left: 35px;
    }
    &.accept {
        background-color: transparent;
        color: $titlebrown;
    }
}

@import "../../styles.scss";

.dropdown {
  background-color: $lowgray;
  border-radius: 33px;
  margin: 5px 0;
  height: min-content !important;

  .dropdown__title {
    transition: max-height 0.2s ease, background-color 0.2s ease, border-color 0.2s ease, color 0.2s ease,
      width 0.2s ease, transform 0.2s ease-in;
    text-align: left;
    cursor: pointer;
    width: 100%;
    border-radius: 200px;
    border: 1px solid transparent;
    padding: 1em 2em;
    font-family: "Poppins";
    font-weight: bold;
    outline: none;
    position: relative;
    font-size: 20px;
    //color: #a0a0a0 !important;
    color: $violeta !important;
    font-weight: bold !important;
    @media only screen and (max-width: 1007px) {
      font-size: 14px !important;
    }
    .plus {
      position: absolute;
      right: 27px;
      top: 50%;
      display: none;
      transform: translateY(-50%);
    }

    .plus-hover {
      position: absolute;
      right: 27px;
      top: 50%;
      display: block;
      transform: translateY(-50%);
    }

    .minus {
      position: absolute;
      right: 27px;
      top: 50%;
      transform: translateY(-50%);
      display: none;
    }

    &:hover {
      background-color: $white;
      border: 1px solid $violeta;
      .plus-hover {
        display: block;
      }
      .plus {
        display: none;
      }
      .minus {
        display: none;
      }
    }
  }


  .close {
    border: 1px solid $lowgray;
    background-color: $lowgray;
    color: $normalgray;
    font-size: 14px !important;
  }

  .open {
    border: 1px solid $violeta !important;
    background-color: $white;
    color: $violeta !important;
    box-shadow: 0 3px 10px rgba(138, 0, 69, 0.42);
    font-size: 14px !important;

    .plus {
      display: none;
    }
    .plus-hover {
      display: none;
    }
    .minus {
      display: block;
    }
  }

  .info {
    font-family: "Poppins";
    font-size: 13px;
    padding: 20px 30px;
    text-align: left;
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    p {
      > a,
      a:link,
      a:visited {
        font-family: "Poppins" !important;
        color: $violeta !important;
        font-size: 11px;
      }
    }

    span {
      margin-top: 30px;
      cursor: pointer;
      font-family: "Poppins" !important;
      color: $violeta !important;
      font-size: 13px;
      display: flex;
      align-items: center;
      font-weight: bold;

      a,
      a:link,
      a:visited {
        font-family: "Poppins" !important;
      }

      &::after {
        margin-left: 10px;
        content: "";
        transform: rotate(45deg);
        height: 5px;
        width: 5px;
        border-top: 1px solid $violeta;
        border-right: 1px solid $violeta;
        border-bottom: 1px solid transparent;
        border-left: 1px solid transparent;
      }
    }
  }

  .collapsable {
    transition: max-height 0.5s ease;
    overflow: hidden;
    position: relative;
    color: $violeta !important;
  }

  .collapsed {
    max-height: 0px;
  }
}

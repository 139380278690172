@import "../../styles.scss";

.cookies-container {
    .sliderbanne__container {
        background: url("../../images/header/header_investment.png") no-repeat;
        background-size: cover;
    }
    h2,
    p,
    a {
        color: $normalgray !important;
        font-family: 'Poppins';
        font-size: 14px;
        text-align: left;
        color: $normalgray
    }
    h1 {
        text-align: left;
    }

    h2 {
        font-family: 'Poppins';
        font-size: 24px;
    }
}

.cookies-container {
    .content-cookies {
        padding: 40px 0;
        width: 80%;

        h1 {
            margin: 0 0 !important;
        }
    }
}

@media only screen and (max-width: 644px) {
    .cookies-container {
        h2 {
            // padding: 0 1em !important;
        }
        p {
            // padding: 0 2em !important;
        }
    }
}

.cc__styledcreate-conatiner {
    height: 137px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
    margin: 20px 20px;
    padding: 0 30px;
    box-sizing: border-box;
    cursor: pointer;
    transition: transform 0.2s ease-in-out;
    &:hover {
        transform: scale(1.05);
    }

    .bg-container {
        border-radius: 20px;
        background-color: rgba(255, 255, 255, 0.5);
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        width: 100%;
        height: 100%;
    }
    > img,
    > p {
        z-index: 2;
    }

    .shadow {
        position: absolute;
        width: 110%;
        height: 120px;
        bottom: -60px;
        z-index: 0;
        left: 50%;
        transform: translateX(-50%);
        background-image: radial-gradient(rgba(27, 27, 27, 0.102), rgba(140, 140, 140, 0) 70%);
    }

    .low-white {
        color: #e3e3e3;
        padding: 0;
        margin: 0;
    }
}

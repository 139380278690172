@import "../../adstyle.scss";

.cc__modification-options {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    border-radius: 100px 0 0 100px;
    box-shadow: 0 8px 15px #e0e0e0;
    box-sizing: border-box;
    padding: 0 20px;
    width: 50%;
    min-width: 350px;
    .cc__item {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        align-items: center;
        .cc__symbol {
            margin: 0 10px;
            border-radius: 30px;
            background-color: $pink;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 0;
            width: 23px;
            height: 23px;
            > img {
                width: 11px;
            }
        }

        .inactivo {
            margin: 0 10px;
            border-radius: 30px;
            background-color: $buttongray;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 0;
            width: 23px;
            height: 23px;
            > img {
                width: 11px;
            }
        }
    }
}

@import "../../adstyle.scss";

.cc__footer {
    width: 100%;
    background-color: $pink;
    display: flex;
    justify-content: center;
    align-items: center;
    > p {
        padding: 9px;
        font-size: 10px;
    }
}

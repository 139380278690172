@import "../../styles.scss";
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

.texxt__slider {
  justify-content: center;
  height: 300px;

  .custom-title {
    text-align: left;
    padding-top: 30px;
    padding-left: 30px;
    width: 30%;
  }

  .texxt__slider_slider {
    .slider {
      width: 50%;
      > * {
        font-size: 20px;
      }

      .slide_comment_text {
        margin-left: 250px;
        margin-right: 250px;
      }
    }
  }
}

.texxt__slider-arrow {
  position: absolute;
  z-index: 1;
  width: 100%;
  display: flex;
  margin-top: 106px;

  .arrow-btn {
    height: 30px;
    width: 30px;
    background-color: white;
    border-radius: 50%;
    //display: inline-block;
    align-items: center;

    img {
      width: 9px;
      height: 17px;

      &.right {
        margin: auto;
        margin-top: 20%;
      }

      &.left {
        margin: auto;
        margin-top: 20%;
      }
    }
  }

  .prev {
    float: left;
    margin-left: 20px;
  }

  .next {
    margin-left: auto;
    margin-right: 20px;;
    float: right;
  }
}


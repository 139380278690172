@import "../../../adstyle.scss";
.upload__button {
    width: 250px;
    height: 230px;
    border-radius: 20px;
    margin: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
}

.cc__modal {
    &-container {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        background-color: rgba(0, 0, 0, 0.5);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    &-info {
        width: 100%;
        max-width: 600px;
        border-radius: 20px;
        box-sizing: border-box;
        padding: 30px;
        background-color: $bodyColor;
    }
}

.form-img {
    width: 100%;
    height: 150px;
    border-radius: 20px;
    margin-top: 20px;
    margin-bottom: 20px;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    > img {
        max-width: 100%;
    }
}

.cc__imglist-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    .cc__cardimage {
        width: 300px;
        height: 137px;
        border-radius: 20px;
        margin: 10px;
        background-size: cover;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-end;
        .options {
            display: flex;
            flex-direction: row;
            width: 100%;
            box-sizing: border-box;
            background-color: rgba(0, 0, 0, 0.5);
            justify-content: space-between;
            border-radius: 0 0 20px 20px;
            > .option {
                padding: 10px 20px;
            }
            > .delete {
                background-color: white;
                border-radius: 0 20px 0 20px;
            }
        }
    }
}

.ley_container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}

.cc__styled-checkbox {
    // background-color: red;
    display: block;
    background-color: $white;
    height: 34px;
    width: 34px;
    border-radius: 100px;
    border: 1px solid $darkgray;
    position: relative;
    box-sizing: border-box;
    padding: 7px;
    cursor: pointer;

    &-selected {
        display: none;
        width: 100%;
        height: 100%;
        background-color: $darkgray;
        border-radius: 100px;
    }

    &-input {
        cursor: pointer;
        position: absolute;
        opacity: 0;
        &:checked {
            + .cc__styled-checkbox-selected {
                display: block;
            }
        }
    }
}

.hide {
    position: absolute;
    opacity: 0;
}

.pdf__container {
    height: 137px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
    margin: 20px 20px;
    padding: 0 30px;
    box-sizing: border-box;
    cursor: pointer;
    transition: transform 0.2s ease-in-out;
    &:hover {
        transform: scale(1.05);
    }
    > img,
    > p,
    > h1 {
        z-index: 2;
    }
    .bg-container {
        border-radius: 20px;
        background-color: rgba(255, 255, 255, 0.5);
        position: absolute;
        top: 0;
        left: 0;
        z-index: 0;
        width: 100%;
        height: 100%;
    }
    .shadow {
        position: absolute;
        width: 110%;
        height: 120px;
        bottom: -60px;
        z-index: 0;
        left: 50%;
        transform: translateX(-50%);
        background-image: radial-gradient(rgba(27, 27, 27, 0.102), rgba(140, 140, 140, 0) 70%);
    }
}

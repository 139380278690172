$gray: rgba(102, 81, 66, 0.4);
$violeta: #eb0271;
$smoothbrown: #655042;
$normalgray: #6d6d6d;
$mediumgray: #c9c5c3;
$lowgray: #eeeceb;
$lowgraya: rgba(238, 236, 235, 0.5);
$white: white;

@font-face {
  font-family: "HelveticaNeueCyr";
  src: url("./fonts/HelveticaNeue Light.ttf");
  src: local("./fonts/HelveticaNeue Light"), url("./fonts/HelveticaNeue Light.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "HelveticaNeueBold";
  src: url("./fonts/Helvetica Neu Bold.ttf");
  src: local("./fonts/Helvetica Neu Bold"), url("./fonts/Helvetica Neu Bold.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
}

@import "styleFix/index.scss";

.card__content__card__media__container {
  background-color: rgba(0,0,0,0.5);
  bottom: 0;
  height: 30%;
  width: 100%;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;

  .card__content__card__media {
    margin: auto;
    color: white;
    font-family: Poppins, goudy-old-style, serif;
    font-size: 18px;
    font-weight: 400;
  }
}
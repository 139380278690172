.cc_programs_masonry {
  .display-grid {
    display: grid;
  }

  .display-none {
    display: none !important;
  }

  .get_programs {
    outline: none;
    cursor: pointer;
    border-radius: 50px;
    background-color: #eb1a71;
    color: white;
    padding: 15px 40px;
    border: none;
    margin-top: 20px;
    align-self: flex-end;
    transition: color 0.3s, transform 0.3s;
    box-shadow: 0 3px 10px hsla(330, 100%, 27%, 0.42);
    border: 1px solid #eb1a71;
    font-family: "Poppins";
    font-weight: bold;

    &:hover {
      transform: scale(1.1);

      background-color: white;
      color: #eb1a71;
      &::after, &::before{
        background-color: #eb1a71;
      }
    }
  }

  .cc_masonry-container {
    margin: 0px auto;
    width: 100%;
    box-sizing: border-box;
    display: grid;
    // max-height: 100vh;
    max-width: 1200px;
    grid-template-columns: repeat(4, 1fr);
    position: relative;

    @media only screen and (max-width: 600px) {
      //   display: flex;
      //   flex-direction: column;
      //   padding: 0;
      //   height: auto;
      display: inline;
      grid-template-columns: 1fr;
      min-height: 322px;
    }

    .cc_masonry-grid {
      position: relative;
      overflow: hidden;
      cursor: pointer;
      box-sizing: border-box;
      margin: 2px;
      min-height: 308px;
      @media only screen and (max-width: 600px) {
        padding: 20px;
        margin: 20px;
        display: block;
        overflow: visible;
        min-height: 322px;
      }

      .card__info-container {
        transition: all 0.2s ease;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        h3 {
          margin-bottom: 0;
          font-family: "Poppins" !important;
          font-weight: bold;
        }

        h3 {
          display: none;
        }

        p {
          //background-color: rgba(0, 0, 0, 0.7);
          color: rgba(255, 255, 255, 1);
          font-weight: bold;
          img {
            display: none;
          }
        }

        @media only screen and (max-width: 600px) {
          bottom: 0;
          top: auto;
          height: 100px;
        }
      }
      @media only screen and (max-width: 600px) {
        .card__info-container {
          h3,
          p {
            color: rgba(255, 255, 255, 1);
            width: 90%;
            text-align: left;
          }
          p {
            margin-top: 2px;
            img {
              display: inline-block;
              margin-right: 5px;
            }
          }
        }
      }
      @media only screen and (min-width: 600px) {
        &:hover {
          .card__info-container {
            background-color: rgba(0, 0, 0, 0.5);
            h3,
            p {
              display: block;
              color: rgba(255, 255, 255, 1);
              background-color: transparent;
            }
          }
        }
      }

      &.items {
        &:nth-child(2) {
          grid-column: span 2;
          grid-row: span 2;
          z-index: 1;
        }

        &:nth-child(3) {
          grid-row: span 2;
          z-index: 1;
        }

        &:nth-child(5) {
          grid-column: span 2;
          z-index: 1;
        }
      }
      &.item-3 {
        &:nth-child(1) {
          grid-column: span 2;
          grid-row: span 2;
          z-index: 1;
        }

        &:nth-child(2) {
          grid-column: span 2;
          z-index: 1;
        }

        &:nth-child(3) {
          grid-row: span 2;
          z-index: 1;
        }
      }
      &.item-2 {
        &:nth-child(2) {
          grid-column: span 2;
          grid-row: span 2;
          z-index: 1;
        }

        &:nth-child(1) {
          grid-column: span 2;
          grid-row: span 2;
          z-index: 1;
        }
      }
      &.item-1 {
        &:nth-child(1) {
          grid-column: span 4;
          grid-row: span 1;
          z-index: 1;
          max-height: 308px;
        }
      }

      @media only screen and (max-width: 600px) {
        grid-column: span 1 !important;
        grid-row: span 1 !important;
        // padding: 5px 20px;
      }

      figure {
        position: absolute;
        width: 100%;
        box-sizing: border-box;
        height: 100%;
        top: 0;
        left: 0;
        margin: 0;

        @media only screen and (max-width: 600px) {
          display: block;
          height: 100%;
        }

        img {
          object-fit: cover;
          width: 100%;
          height: 100%;

          @media only screen and (max-width: 600px) {
            object-fit: cover;
            height: 100%;
          }
        }
      }
    }
  }
}

.social {
  display: none;
  margin-top: 100px;

  @media only screen and (max-width: 1250px) {
    display: block;
  }

  a {
    img {
      padding: 30px;
      transition: transform 0.3s;

      &:hover {
        transform: scale(1.2);
      }
    }
  }
}

.card__info__title {
  font-family: "Poppins" !important;
  font-weight: bold;
}

@import "../../styles.scss";

.open_simulation {
  display: flex;
}

.close_simulation {
  display: none;
}

.showing_modal {
  overflow: hidden;
}

.simulation__container {
  position: fixed;
  top: 0 !important;
  left: 0 !important;
  width: 100%;
  height: 100%;
  overflow: auto;
  z-index: 10;
  background-color: rgba($color: #000, $alpha: 0.5);
  backdrop-filter: blur(10px);
  justify-content: center;
  align-items: center;

  .close-simulation {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }

  .simulation__wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-sizing: border-box;
    max-width: 750px;
    min-height: 750px;
    border-radius: 7px;
    margin: 0 auto;
    margin-top: 150px;
    background-image: linear-gradient(-180deg, $white, $mediumgray);
    box-shadow: 0 20px 30px rgba(0, 0, 0, 0.3);
    position: relative;
    overflow: hidden;

    .simulation-bg {
      position: absolute;
      left: 0px;
      @media only screen and (min-width: 600px) {
        left: -100px;
      }
      bottom: 0;
      z-index: 2;
      max-height: 80%;
      opacity: 0.05;
    }

    button,
    input,
    textarea {
      z-index: 2 !important;
    }

    .simulation__menu {
      background-color: $lowgray;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      box-sizing: border-box;
      border-radius: 7px 7px 0 0;
      padding: 20px 20px;
      z-index: 3;

      @media only screen and (max-width: 640px) {
        padding: 15px;
      }

      .optiones_menu {
        display: flex;
        flex-direction: row !important;
        align-items: center;
        .x-close {
          width: 10px;
          height: 10px;
          position: relative;
          transform: rotate(45deg);
          margin-left: 5px;
          cursor: pointer;

          &::after {
            content: " ";
            width: 16px;
            height: 2px;
            background-color: $violeta;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }

          &::before {
            content: " ";
            width: 2px;
            height: 16px;
            background-color: $violeta;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
        }

        .option-menu {
          img {
            height: 10px;
          }

          p {
            font-weight: 700;
          }

          margin: 0 10px;
        }
      }
    }

    .questions {
      display: flex;
      flex-direction: column;
      align-items: center;
      z-index: 3;

      > p {
        text-align: left;
        width: 90%;
      }

      > form {
        width: 90%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 0;

        .check-0 {
          margin-top: 30px;
        }

        .label-checkbox {
          width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: flex-start !important;
          align-items: flex-s;

          > input {
            -webkit-appearance: checkbox;
            appearance: auto;
          }

          p {
            margin: 0;
            padding: 0;
            padding-left: 5px;
            text-align: left;
          }
        }

        .label-input {
          width: 100%;
          text-align: left;
          margin-top: 15px;

          p {
            font-size: 14px !important;
            margin: 0;
            display: inline;
          }

          input[type="checkbox"] {
            display: inline;
          }

          input[type="text"],
          input[type="number"],
          input[type="email"],
          textarea {
            box-sizing: border-box;
            width: 100%;
            padding: 10px;
            resize: none;
            border-radius: 7px;
            border: 1px solid #eeeceb;
          }

          textarea {
            height: 295px;
          }
        }
      }
    }

    .options_container {
      margin-top: 20px;
      min-width: 875px;
      user-select: none;
      margin-bottom: 100px;

      .option {
        border: 1px solid $normalgray;
        user-select: none;
        cursor: pointer;
        outline: none;
        padding: 7px 20px;
        border-radius: 50px;
        margin: 0 10px;
        background-color: $white;
        color: $normalgray;
        font-family: "Poppins";
        min-width: 69px;
        font-size: 12px;
        transition: transform 0.2s ease;
        appearance: none;
        -webkit-appearance: none;

        &:hover {
          transform: scale(1.1);
        }
      }

      .selected {
        background-color: $normalgray;
        color: $white;
      }
    }

    .end_form {
      padding: inherit 0;
      margin: 0 !important;
    }
    .form__button,
    .form__buttonn {
      padding-top: 10px;
      align-self: flex-end;
      font-family: 'Poppins';
      font-weight: 900;
      display: flex;
      flex-direction: row;
      width: 90%;
      box-sizing: border-box;
      justify-content: flex-end;
      padding: 20px 20px;
      padding-top: 20px;
      margin: 0 auto;
      margin-bottom: 30px;
      .cancel {
        // margin-right: 30px;
        outline: none;
        cursor: pointer;
        border-radius: 50px;

        background-color: white;
        color: #eb1a71;
        border: 1px solid #eb1a71;

        padding: 15px 40px;
        border: none;
        //   margin-top: 20px;
        align-self: flex-end;
        transition: color 0.3s, transform 0.3s;
        box-shadow: 0 3px 10px hsla(330, 100%, 27%, 0.42);

        &:hover {
          transform: scale(1.1);
          background-color: #eb1a71;
          color: white;
        }
        &::after {
          content: none;
        }
      }

      .send {
        @media only screen and (min-width: 645px) {
          margin-left: 30px !important;
        }
        font-weight: bold !important;
        outline: none;
        cursor: pointer;
        border-radius: 50px;
        background-color: #eb1a71;
        color: white;
        padding: 15px 40px !important;
        border: none !important;
        //   margin-top: 20px;
        align-self: flex-end;
        transition: color 0.3s, transform 0.3s;
        box-shadow: 0 3px 10px hsla(330, 100%, 27%, 0.42);

        &:hover {
          transform: scale(1.1);

          background-color: white;
          color: #eb1a71;
          border: 1px solid #eb1a71;
        }
        &::after {
          content: none;
        }
      }
    }
    .bottom_line {
      display: flex;
      flex-direction: column;
      margin-bottom: 80px;

      .simulation_status {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 70%;
        margin: 0 auto;

        .simulator {
          display: flex;
          flex-grow: 1;
          align-items: center;
          flex-direction: row;

          &:first-child {
            flex-grow: 0;
          }

          .bullet_container {
            width: 17px;
            height: 17px;
            border-radius: 100%;
            background-color: $white;
            border: 1px solid $mediumgray;
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;

            p {
              position: absolute;
              top: 15px;
              left: 50%;
              transform: translateX(-50%);
              font-size: 10px;
            }

            .bullet {
              width: 7px;
              height: 7px;
              border-radius: 100%;
              background-color: $white;
              z-index: 1000;
            }
          }

          .line {
            border-bottom: 1px solid $mediumgray;
            flex-grow: 1;
          }
        }

        .active {
          .bullet_container {
            border-color: $violeta;

            .bullet {
              background-color: #5beb00;
            }
          }

          .line {
            border-color: $violeta;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 644px) {
  .simulation__container {
    .simulation__wrapper {
      // max-height: 95%;
      display: block;
      margin-top: 220px;
      width: 90%;

      .questions {
        .options_container {
          min-width: unset;
          display: flex;
          flex-direction: column;
          align-items: center;

          .option {
            margin: 1em auto;
          }
        }
      }

      .form__button {
        justify-content: center;
        align-items: center;
        padding: 0 !important;
        margin-left: 0;
        margin: 2em auto 2em !important;
        flex-direction: column;
        justify-content: center;
        // width: unset;

        .send,
        .cancel {
          margin: 0.5em auto;
        }
      }
    }
  }
}

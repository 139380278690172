@import "../../../adstyle.scss";

.cc__main-promotor {
    width: 100%;
    .cc__left {
        width: 100%;
        .cc__pages-list {
            display: flex;
            justify-content: flex-start;
            flex-wrap: wrap;
            width: 100%;
            > div {
                margin: 20px 20px;
            }
        }
    }

    .margin-40px {
        margin-left: 40px;
    }

    .cc__fullsection {
        width: 100%;
        padding: 0;
    }
    
    .main_title {
        display: flex;
        width: 100%;
        padding: 20px 0;
        justify-content: space-between;
        flex-direction: row;
        align-items: center;
        box-sizing: border-box;
        padding: 10px 0;
        // width: 100px;
        &:first-child {
            flex: 0;
        }
        &:last-child {
            flex: 1;
            width: 100%;
        }
    }
    .cc__center {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        padding-bottom: 40px;
        align-items: center;
    }

    .cc__sidebar-container {
        > div {
            margin-top: 10px;
        }
    }
    .cc__section {
        flex-basis: 100%;
    }
}

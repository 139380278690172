@import "../../adstyle.scss";

$containerBG: #e8e8e8;

.StyleEditableProject {
    display: flex;
    flex-direction: row;
    width: 300px;
    height: 250px;
    flex-direction: column;
    justify-content: space-between;
    background-color: $containerBG;
    box-sizing: border-box;
    border-radius: 20px;
    position: relative;
    box-shadow: 0 25px 30px hsla(0, 0%, 0%, 0.25);
    .cc__styled_editable-shadow {
        position: absolute;
        width: 110%;
        height: 100px;
        bottom: -50px;
        z-index: -2;
        left: 50%;
        transform: translateX(-50%);
        background-image: radial-gradient(rgba(27, 27, 27, 0.3), rgba(140, 140, 140, 0) 70%);
    }
    .cc__styled_editable-bg {
        width: 100%;
        height: 100%;
        border-radius: 20px;
        position: absolute;
        top: 0;
        left: 0;
        mix-blend-mode: multiply;
    }
    .cc__styled_editable-inner {
        z-index: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        padding: 30px;
        align-self: flex-end;

        align-items: flex-start;
        &:nth-child(3) {
            align-items: center;
        }
        > p {
            margin: 0;
            font: $label-font-poppins-bold;
            color: $titlebrown;
        }
        .cc__editable-img {
            height: 50px;
        }
        .cc__editable-icon {
            width: 26px;
        }
        > p {
            font: $editable_poppins-18;
        }
    }
    .info-container {
        height: 75px;
        width: 100%;
        display: flex;
        position: relative;
        flex-direction: row;
        padding: 0;
        border-radius: 0 0 20px 20px;
        justify-content: space-between;
        background-color: rgba(0, 0, 0, 0.5);
        .info {
            padding-left: 30px;
            > p {
                text-align: left;
                color: white;
                margin: 5px 0;
            }
            .small-10-white {
                font-size: 10px;
            }
        }
        > a {
            padding-right: 30px;
        }
    }
}

.cc__styled-checkbox {
    // background-color: red;
    display: block;
    background-color: $white;
    height: 20px;
    width: 20px;
    border-radius: 100px;
    border: 1px solid $darkgray;
    position: relative;
    box-sizing: border-box;
    padding: 7px;
    cursor: pointer;

    &-selected {
        display: none;
        width: 100%;
        height: 100%;
        background-color: $darkgray;
        border-radius: 100px;
    }

    &-input {
        cursor: pointer;
        position: absolute;
        opacity: 0;
        &:checked {
            + .cc__styled-checkbox-selected {
                display: block;
            }
        }
    }
}

@import "../../styles.scss";


.program__container {
  .program__info-container {
    display: flex;
    position: relative;
    max-width: 1200px;
    padding: 10px;

    .download {
      background-color: $violeta;
      border: none !important;
      color: white !important;
      padding: 15px 30px;
      border-radius: 100px;
      font-family: "Poppins" !important;
      font-size: 13px;
      font-weight: bold;
      transition: color 0.3s, transform 0.3s;
      box-shadow: 0 3px 10px hsla(330, 100%, 27%, 0.42);
      border: 1px solid #eb1a71;

      &:hover {
        transform: scale(1.1);

        background-color: white;
        color: #eb1a71 !important;

        &::after {
          border-left-color: $violeta;
          border-bottom-color: $violeta;
        }
      }

      &::after {
        content: "";
        display: inline-block;
        margin-left: 15px;
        width: 5px;
        height: 5px;
        border: 20px solid transparent;
        vertical-align: middle;
        transform: rotate(-135deg);
        border-left: 2px solid $white;
        border-bottom: 2px solid $white;
        border-top: 2px solid transparent;
        border-right: 2px solid transparent;
        z-index: 3;
        margin-top: 4px;
      }
    }

    @media only screen and (min-width: 641px) and (max-width: 1007px) {
      max-width: 70%;
      flex-direction: column;
    }

    @media only screen and (max-width: 640px) {
      flex-direction: column;
      padding: 0;
      max-width: 100%;
      box-sizing: border-box;
      padding: 20px;
    }

    //Estilos de los adornitos

    .program__info-data {
      box-sizing: border-box;
      padding-right: 40px;

      @media only screen and (min-width: 641px) and (max-width: 1007px) {
        margin-bottom: 40px;
        .lois {
          margin: 20px 0;
          display: flex;
          justify-content: center;
        }
      }

      @media only screen and (max-width: 640px) {
        width: 100%;
        padding: 0;
        margin-bottom: 40px;
      }

      .lois {
        margin: 20px 0;
        display: flex;
      }

      h1,
      h2,
      p,
      span {
        text-align: left;
      }

      p {
        color: "#A0A0A0" !important;
      }

      > h1 {
        padding: 0;
        margin: 0;

        @media only screen and (max-width: 640px) {
          padding: 0 !important;
          margin: 0 !important;
        }
      }

      form {
        margin-top: 60px;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        font-family: "Poppins" !important;
        font-weight: bold;
      }

      .program__info-data__calendar {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        padding-top: 40px !important;

        > img {
          width: 25px;
        }

        > span {
          margin-left: 10px;
        }
      }
    }
  }

  .program__interested-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin: 0 auto;
    width: 70%;

    .title-h2-pink {
      font-weight: bold;
      margin-bottom: 0;
      font-family: "Poppins" !important;
      font-weight: bold;
    }

    h2 {
      margin-left: 10px !important;
    }

    @media only screen and (min-width: 641px) and (max-width: 1007px) {
      width: 100%;
    }

    @media screen and (max-width: 644px) {
      width: 100%;
      box-sizing: border-box;
    }

    > form {
      border-radius: 7px;
      box-shadow: 0 3px 10px hsla(330, 100%, 27%, 0.42);
      width: 100%;
      background-color: $violeta;
      display: flex;
      flex-direction: row;
      align-content: flex-end;
      justify-items: flex-end;
      justify-content: center;
      align-items: center;
      box-sizing: border-box;
      padding: 15px;
      min-height: 120px;
      margin-top: 2em;

      @media screen and (max-width: 644px) {
        flex-direction: column;
        box-sizing: border-box;
        margin: 0 auto;
        padding: 20px !important;
        border-radius: 0;
      }

      .form__group {
        position: relative;
        padding: 15px 0 0;
        margin: 10px 30px;
        width: 100%;

        .form__field {
          font-family: inherit;
          width: 100%;
          border: 0;
          border-bottom: 1px solid $white;
          outline: 0;
          font-size: 1.3rem;
          color: $white;
          padding: 7px 0;
          background: transparent;
          transition: border-color 0.2s;
          color: $white;
          font-family: "Poppins";
          font-size: 16px;

          &::placeholder {
            color: transparent;
          }

          &:placeholder-shown ~ .form__label {
            font-size: 14px;
            font-family: "Poppins";
            cursor: text;
            top: 20px;
          }

          &:focus {
            ~ .form__label {
              position: absolute;
              top: 0;
              display: block;
              transition: 0.2s;
              font-size: 1rem;
              color: $white;
              font-weight: 700;
            }

            padding-bottom: 6px;
            font-weight: 700;
            border-bottom: 1px solid $white;
          }

          &:required,
          &:invalid {
            box-shadow: none;
          }
        }

        .form__label {
          position: absolute;
          top: 0;
          display: block;
          transition: 0.2s;
          font-size: 1rem;
          color: $white;
        }
      }

      button {
        background-color: $white;
        border: none;
        color: $violeta;
        padding: 15px 30px;
        border-radius: 100px;
        transition: color 0.3s, transform 0.3s;
        box-shadow: 0 3px 10px hsla(330, 100%, 27%, 0.42);
        border: 1px solid $white;
        display: block;
        cursor: pointer;
        white-space: nowrap;
        font-family: "Poppins" !important;
        font-weight: bold;

        &:hover {
          transform: scale(1.1);

          background-color: $violeta;
          color: $white;

          &::after {
            border-left-color: $white;
            border-bottom-color: $white;
          }
        }

        &::after {
          content: "";
          display: inline-block;
          margin-left: 15px;
          width: 5px;
          height: 5px;
          border: 20px solid transparent;
          vertical-align: middle;
          transform: rotate(-135deg);
          border-left: 2px solid $violeta;
          border-bottom: 2px solid $violeta;
          border-top: 2px solid transparent;
          border-right: 2px solid transparent;
          z-index: 3;
        }
      }
    }
  }

  .current_projects-wrappers {
    width: 100%;
  }

  .program-status {
    font-family: Poppins, goudy-old-style, serif;
    font-style: italic;
    font-size: 16px;
    color: $violeta;
  }

  .program__company-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 10px auto;
    width: 70%;
    box-sizing: border-box;
    max-width: 1100px;
    padding: 10px;

    .company__info {
      h1 {
        u {
          display: table-cell;
        }
      }
      a {
        font-family: 'Poppins' !important;
        font-size: 14px;
        color: $violeta !important;
      }
    }

    img {
      max-width: 320px;
    }

    @media only screen and (min-width: 641px) and (max-width: 1007px) {
      flex-direction: column;
      margin: 10px auto;
      width: 80%;

      > img {
        margin-top: 50px;
        height: 100px;
      }
    }

    @media only screen and (max-width: 640px) {
      width: 100%;
      flex-direction: column;
      padding: 20px;
      margin: 10px auto;

      img {
        margin-top: 60px;
      }
    }

    .company__info {
      padding: 0 100px 0 0;
      box-sizing: border-box;

      @media only screen and (min-width: 641px) and (max-width: 1007px) {
        padding: 0;
      }

      @media only screen and (max-width: 640px) {
        padding: 0px;
        width: 100%;
        margin: 0px;
      }

      h1 {
        text-align: left;
      }

      p {
        text-align: left;
        color: "#A0A0A0" !important;
      }
    }
  }

  .program__map {
    width: 100%;
    position: relative;
    box-sizing: border-box;
    padding: 70px 0;
    margin-top: 200px;

    .cc_styled-bar {
      position: absolute;
      top: 0;
      right: 0;
      height: 100%;
      width: 67%;
      background-color: $lowgraya;
      z-index: -1;
      border-radius: 7px 0 0 7px;
    }

    .program__map-wrapper {
      padding: 20px;
      box-sizing: border-box;
      position: relative;
      display: flex;
      flex-direction: row;
      margin: 0 auto;
      justify-content: space-between;
      align-items: center;
      width: 70%;
      margin: 0 auto;
      max-width: 1100px;

      @media only screen and (max-width: 640px) {
        flex-direction: column;
        width: 100%;
        max-width: 100%;
        padding: 0px;
        h1 {
          padding: 0 !important;
          margin: 0 !important;
        }
      }

      .map_container {

        @media only screen and (min-width: 641px) and (max-width: 1007px) {
          width: 70% !important;
        }

        @media only screen and (max-width: 640px) {
          width: 100%;
          order: 2;
          margin-top: 40px;

          > div > div > div {
            width: 70% !important;
          }
        }

        iframe {
          width: 100% !important;
          display: block;
        }
      }

      .info_venta {
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
        // padding: 50px;
        margin-left: 30px;
        max-width: 500px;

        @media only screen and (max-width: 640px) {
          padding: 10px;
        }

        h1 {
          text-align: left;
          margin: 0;

          @media only screen and (max-width: 640px) {
            padding: 10px;
            margin: 10px !important;
          }
        }

        p {
          text-align: left;
        }

        .contact_data-container {
          margin-top: 10px;

          .contact_data-item {
            display: flex;
            flex-direction: row;
            margin: 0 30px;
            margin-bottom: 20px;
            img {
              width: 24px !important;
            }
            @media only screen and (max-width: 640px) {
              margin: 0 0 20px 0;
              margin-left: 10px;
            }
            .contanct_data-info {
              display: flex;
              flex-direction: column;
              margin-left: 20px;
              justify-content: center;
              align-items: flex-start;
              > a {
                font-size: 30px;
              }
              @media only screen and (max-width: 640px) {
                > a {
                  font-size: 24px;
                }
              }
              .time {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                width: 110%;

                @media only screen and (max-width: 640px) {
                  flex-direction: column;
                }
              }

              .phone-violet-a {
                color: $violeta !important;
                font-family: 'Poppins' !important;
                font-weight: bold;
              }

              a,
              p {
                margin: 2px;
                padding: 0;
              }
            }
          }
        }
      }
    }
  }

  .responsive {
    display: none;
  }
}

@media only screen and (min-width: 645px) and (max-width: 1250px) {
  .program__container {
    overflow: hidden;

    .program__info-container {
      max-width: 70%;

      .program__info-data {
        padding-right: 0;
        margin-bottom: 0;

        .desktop {
          display: none;
        }
      }

      .slide_show___container {
        .ss__selectedimg {
          width: 100%;
          height: 350px;
        }

        .ss__carrousel {
          justify-content: space-between;

          .ss__image-container {
            &:first-child {
              margin-left: 0;
            }
          }
        }
      }

      form {
        border-radius: 7px;
        &.responsive {
          justify-content: center !important;
          display: flex !important;
          flex-direction: row;
          font-family: "Poppins" !important;
          font-weight: bold;

          button {
            font-family: "Poppins" !important;
            font-weight: bold;
          }
        }
      }
    }

    .program__interested-form {
      width: 90%;

      form {
        // flex-wrap: wrap;
        justify-content: flex-start;
        flex-direction: row;
      }
    }

    .program__map {
      margin-top: 50px;
      .program__map-wrapper {
        flex-direction: row;
        width: 90%;
        padding: 10px !important;
        margin: 10px auto !important;

        .contact_data-container {
          .contact_data-item {
            img {
              width: 23px !important;
            }
            &:last-child {
              .contanct_data-info {
                .phone-violet-a {
                  font-size: 20px !important;
                  font-weight: bold;
                }

                .time {
                  display: flex;
                  flex-direction: row;
                  background-color: red;
                }
              }
            }
          }
        }
      }
    }

    .program__company-container {
      flex-direction: row;
      margin: 0 auto;
      padding: 20px 0 20px 0;
      img {
        margin-left: 40px;
        max-width: 250px;
        height: auto;
      }

      .company__info {
        padding: 0px;

        .title-h1-brown {
          u {
            display: inline-block;
          }
        }
      }
    }

    .program__map {
      .program__map-wrapper {
        .info_venta {
          margin-left: 20px;
          padding: 0;

          h1 {
            font-size: 45px !important;
            padding: 0;
            margin-left: 0 !important;
            margin-bottom: 20px;
          }
        }
        .map_container {
          width: 400px !important;
          height: 439px;
        }
      }
    }
  }
}

@media only screen and (max-width: 640px) {
  .program__container {
    overflow: hidden;

    .cc-styled_wrapper {
      &.cc-left {
        margin-left: 0 !important;
        padding: 0;
      }
    }

    .program__info-container {
      div {
        &.responsive {
          justify-content: center !important;
          display: flex !important;
          font-family: "Poppins" !important;
          font-weight: bold;
          margin-top: 25px;
          margin-bottom: 2em;

          button {
            font-family: "Poppins" !important;
            font-weight: bold;
          }
        }
      }

      .desktop {
        display: none;
      }

      .program__info-data {
        p {
          text-align: left;
        }

        .lois {
          flex-wrap: wrap;
          justify-content: space-around;

          img {
            margin-right: 0;
            margin-bottom: 1em;
          }
        }
      }
    }

    .slide_show___container {
      .ss__selectedimg {
        width: 100%;
        height: 180px;
      }

      .ss__carrousel {
        justify-content: space-between;

        .ss__image-container {
          width: 50px;
          height: 40px;

          &:first-child {
            margin-left: 0;
          }
        }
      }
    }

    .program__interested-form {
      h2 {
        margin-bottom: 1.5em !important;
      }

      form {
        height: 270px;

        button {
          margin: 2em auto 1em;
        }
      }
    }

    .program__map {
      .info_venta {
        margin-left: 0px !important;
        padding: 30px !important;
        .time {
          p {
            text-align: left !important;
          }
        }
      }

      .program__map-wrapper {
        .map_container {
          box-sizing: border-box;
          padding: 30px;
          width: 96%;
          height: 100px !important;
        }
      }

    }

    .program__company-container {
      .company__info {
        h1 {
          margin: 1em 0 !important;
        }
      }
      img {
        max-height: 200px;
      }
    }
  }
}

.current_projects-wrapper {
  margin: auto;
  margin-bottom: 80px;
  padding: 0 70px;
}

.description_title {
  text-align: left;
  color: rgba(0, 0, 0, 0.8);
}

@import "../../styles.scss";

html {
  scroll-behavior: smooth;
}

.menu__link-img {
  animation: none;
  &:hover::after {
    border-color: transparent !important;
    width: 0 !important;
  }
  &:hover::before {
    width: 0 !important;
    border-color: transparent !important;
  }
}

.menu__container {
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;

  .menu__container-top {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 10px 150px;
    align-items: center;
    background-color: #655042 !important;
    color: white;

    p {
      color: $smoothbrown;
    }

    @media only screen and (min-width: 645px) and (max-width: 1250px) {
      padding: 10px 50px;
    }

    .menu__top-right {
      display: flex;
      flex-direction: row;
      align-items: center;


      p {
        border-right: 1px solid #e1e1e1;
        padding: 0 15px;
        margin: 0;

        img {
          margin-right: 10px;
          background-color: white !important;
        }

        a {
          font-family: "Poppins" !important;
          color: white;
          font-size: 14px;
        }
      }

      img {
        margin-left: 10px;
      }
    }
  }

  .menu__container-bot {
    border-top: 1px solid #e1e1e1;
    padding: 10px 90px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    padding-top: 10px;
    background-color: white !important;

    @media only screen and (min-width: 645px) and (max-width: 1250px) {
      padding: 10px 50px;
    }

    .menu__top-right {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    a {
      margin-left: 35px;
      position: relative;
      padding-bottom: 0.3em;

      &:nth-child(1) {
        margin-left: 0;
      }

      &::after {
        backface-visibility: hidden;
        border-bottom: 1px solid $violeta;
        content: " ";
        position: absolute;
        transition: all 280ms ease-in-out;
        width: 0;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
      }

      &:hover::after {
        bottom: 0;
        left: 50%;
        backface-visibility: hidden;
        border-color: $violeta;
        transition: width 350ms ease-in-out;
        width: 100%;
        transform: translateX(-50%);
      }
    }

    .selectedmenu {
      padding-right: 0;

      &::after {
        bottom: 0;
        left: 50%;
        backface-visibility: hidden;
        border-color: $violeta;
        transition: width 350ms ease-in-out;
        width: 100%;
        transform: translateX(-50%);
      }
    }

    .unselectedmenu {
      color: $smoothbrown !important;
    }
  }

  .menu-responsive {
    display: none;
  }

  .x-icon {
    display: none;
  }
}

@media only screen and (max-width: 1251px) {
  .menu__top-left {
    .social {
      margin: 0px;
      height: 0px;
      visibility: hidden;
      .fb-like iframe {
        visibility: hidden !important;
      }
    }
  }
}

@media only screen and (min-width: 1251px) {
  .menu__bot-right {
    display: none !important;
  }

  .menu__top-left {
    .social {
      display: flex;
      margin-top: 0;
      padding: 0;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-content: flex-start;
      justify-items: self-start;

      .fb-like {
        margin-right: 0px !important;
        span {
          iframe {
            width: 80px !important;
          }
        }
      }

      img {
        //margin-top: 0;
        //padding-top: 0;
        padding: 2px 10px;
        height: 15px;
      }
    }
  }

  .small-logo {
    display: none !important;
  }

  .desktop {
    display: flex !important;
  }
}

@media only screen and (min-width: 320px) and (max-width: 1250px) {
  .menu__container-top {
    padding-bottom: 2em !important;
    padding-top: 2em !important;
    border-bottom: solid rgba($color: #fff, $alpha: 0.5) 2px;
  }

  .menu__top-left {
    p {
      display: none;
    }

    img {
      height: 21px;
    }
  }

  .menu__top-right {
    p {
      display: none;
    }

    img {
      height: 20px;
    }

    .small-logo {
      position: absolute;
      left: 50%;
      transform: translateX(-50px);
      margin: 0 !important;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      img {
        width: 75px !important;
        height: 45px;
        height: 100px;
        margin: 0;
      }
    }
  }

  .menu__container-bot {
    z-index: 2;
    padding: 20px;
    box-sizing: border-box;
    margin: 0;
    padding-top: 27px;
    display: flex;
    position: relative;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    display: none !important;

    .display-none {
      display: none !important;
    }
  }

  .menu__bot-right {
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    height: 100vh;
    z-index: 2;

    // border-radius: 1px solid red;
    &::after {
      position: absolute;
      content: "";
      width: 100%;
      height: 100%;
      background-color: rgba($color: #655042, $alpha: 0.9);
      //mix-blend-mode: multiply;
      backdrop-filter: blur(15px);
      filter: brightness(0.2);
    }

    .menu__bot-right-container {
      position: absolute;
      top: 0;
      left: 0;
      height: 100vh;
      width: 100vw;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-direction: column;
      z-index: 3;
      margin-top: auto;
      margin-bottom: auto;

      .x-icon {
        cursor: pointer;
        display: inline;
        transition: transform 0.3s;

        &:hover {
          transform: scale(1.2);
        }
      }

      a {
        text-align: center;
        color: white !important;
        font-size: 26px;
        position: relative;
        margin: 1em auto;
      }
    }

    &.display-none {
      display: none !important;
    }
  }

  .menu-responsive {
    display: block !important;
    position: relative;
    height: 30px;
    width: 30px;
    // top: 50%;
    // transform: translateY(-50%);
    // left: 30px;
    z-index: 4px !important;
    cursor: pointer;
    transition: transform 0.2s ease-in;

    &:hover {
      transform: scale(1.2);
    }
  }
}

@media only screen and (max-width: 644px) {
  .menu__container {
    .menu__container-top {
      padding: 10px 25px;
    }
  }

  .menu__bot-right {
    .tablet {
      display: none;
    }

    .phone {
      margin-top: 4em;
    }
  }
}

.top-container-links {
  color: white !important;
}

.top-container-links-background {
  margin: auto;
  img {
    background-color: white !important;
  }
}

.menu__bot-right-container-parent {
  margin-top: auto;
  margin-bottom: auto;
}

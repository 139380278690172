@import "../../adstyle.scss";

.cc__menu-container {
    background-color: $containercolor;
    width: 313px !important;
    border-radius: 0 50px 50px 0;
    box-shadow: 0 3px 6px $pinkshadow;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
}

.cc__admin-link {
    height: 74px;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 30px 0;

    > .ccadmin__menu-font {
        font-family: 'Poppins';
        text-align: left;
        margin-left: 25px;
        color: $titlebrown;
    }

    img {
        margin-left: 30px;
        height: 50px;
    }

    &_active {
        box-sizing: border-box;
        margin: 30px 0;
        width: 90%;
        align-self: start;
        padding-right: 10px;
        height: 74px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        border-radius: 0 50px 50px 0;
        background-color: $pink;
        color: white !important;
        // box-shadow: 0 6px 6px $pinkshadow;
        img {
            margin-left: 30px;
            height: 50px;
            filter: brightness(0) invert(1);
        }

        > .ccadmin__menu-font {
            font-family: 'Poppins';
            color: white;
            text-align: left;
            margin-left: 25px;
        }

        a {
            text-align: left;
        }
    }
    a {
        text-align: left;
    }
}

.cc__menu-logo {
    margin-top: 70px;
    width: 75px;
}

@import "../../adstyle.scss";

.cc__logout {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    flex-direction: column;
    height: 60px;
    .cc__logout-content {
        height: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        .logout {
            position: relative;
            // padding-right: 30px;
            padding-left: 10px;
            margin: 10px 40px;
            margin-left: 15px;
            font: $admin_logout_button;
            color: $titlebrown;
            // &::after {
            //     content: " ";
            //     height: 7px;
            //     width: 7px;
            //     border-bottom: 1px solid $pink;
            //     border-right: 1px solid $pink;
            //     position: absolute;
            //     transform: rotate(45deg);
            //     right: 0px;
            // }
        }
    }
}

$white: white;
$darkgray: #707070;
$pink: #eb1a71;
$containercolor: #eeeceb;
$pinkshadow: hsla(330, 100%, 27%, 0.42);
$titlebrown: #655042;
$textgray: #a0a0a0;
$buttongray: #b7b6b6;
$bodyColor: #f6f5f5;

$title-font-goudy: 400 3.182rem Poppins, goudy-old-style, serif;
$title-font-helvetica-bold: 400 2rem "HelveticaNeueBold";
$title-font-poppins-bold: 400 2rem "Poppins";
$helvetica-14px-bold: 400 0.875em "HelveticaNeueBold";
$poppins-14px-bold: 400 0.875em "Poppins";
$helvetica-14px-regular: 400 0.875em HelveticaNeueCyr;
$poppins-14px-regular: 400 0.875em "Poppins";
$label-font-helvetica: 400 0.875em HelveticaNeueCyr;
$label-font-poppins: 400 0.875em 'Poppins';
$label-font-helvetica-bold: 400 0.875em "HelveticaNeueBold";
$label-font-poppins-bold: 400 0.875em "Poppins";
$menu-font-goudy: 400 1em Poppins, goudy-old-style, serif;
$menu-font-helvetica: 800 1rem "HelveticaNeueBold";
$font-helvetica-32px: 800 32px "HelveticaNeueBold";
$section_name-font-goudy: 400 4.76rem Poppins, goudy-old-style, serif;

$editable_helvetica-18: 800 1.125 "HelveticaNeueBold";
$editable_poppins-18: 800 1.125 "Poppins";
$editable_helvetica-24: 800 24px "HelveticaNeueBold";
$admin_logout_button: 400 12px Poppins, goudy-old-style, serif;

@font-face {
    font-family: "HelveticaNeueCyr";
    src: url("../fonts/HelveticaNeue Light.ttf");
    src: local("../fonts/HelveticaNeue Light"), url("../fonts/HelveticaNeue Light.ttf") format("truetype");
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: "HelveticaNeueBold";
    src: url("../fonts/Helvetica Neu Bold.ttf");
    src: local("../fonts/Helvetica Neu Bold"), url("../fonts/Helvetica Neu Bold.ttf") format("truetype");
    font-weight: 900;
    font-style: normal;
}
//---------------------
//TIPO DE CONTENEDORES
//---------------------
.cc__admin-container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    max-width: 100%;
    background-color: $bodyColor;
    min-height: calc(100vh - 50px);
    // padding-bottom: 100px;
}

.cc__row-2itmes {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    > div:nth-child(1) {
        flex-basis: 50%;
    }
    > div:nth-child(2) {
        flex-basis: 20%;
    }
}
.cc__row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    .cc__input {
        // width: 33.4%;
        padding: 13px 0;
        flex-basis: 26%;
    }
    > div {
        padding: 13px 0;
        flex-basis: 26%;
    }
}
.cc__row-container {
    display: flex;
    flex-direction: row;
    > div {
        flex: 1;
    }
}

.cc__button-2 {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
}

.cc__admin-info_wrapper {
    flex: 1;
}

//------------------------------------
//TERMINACIÓN DE TÍPOS DE CONTENEDORES
//------------------------------------

//------------------------------------
//Tipografía
//------------------------------------
.styled__p-white {
    font: $poppins-14px-bold;
    color: $white;
}
.styled__p-gray {
    font: $poppins-14px-bold;
    color: $textgray;
}

.styled__label-pink {
    font: $poppins-14px-bold;
    color: $pink;
}

.styled__label-pink-18px {
    font: $label-font-poppins-bold;
    color: $pink;
}
.styled__label-brown-16px {
    font: $label-font-poppins-bold;
    color: $titlebrown;
}
.styled__label-gray-18px {
    font-family: 'Poppins';
    color: $textgray;
}

.styled__h2-brown {
    font: $title-font-poppins-bold;
    color: $titlebrown;
}
.styled__h1-brown_underline {
    font: $title-font-poppins-bold;
    color: $titlebrown;
    border-bottom: 2px solid $pink;
}
.styled__h1-brown {
    font-family: 'Poppins';
    font-size: 32px;
    font-weight: 800;
    color: $titlebrown;
}

.styled__h3-gray {
    font: $editable_helvetica-24;
    color: $textgray;
}

u {
    border-bottom: 2px solid $pink;
}

.cc__admin-info_wrapper {
    // flex-grow: 1;
}

.cc__division {
    border-bottom: 1px solid $pink;
    width: 100%;
}

.cc__button {
    border: none;
    padding: 17px;
    width: 200px;
    border-radius: 100px;
    font: $poppins-14px-bold;
    cursor: pointer;
    transition: transform 0.2s;
    &:hover {
        transform: scale(1.1);
    }
    &.cancel {
        background-color: $pink;
        color: $white;
        box-shadow: 0px 3px 10px $pinkshadow;
    }
    &.accept {
        background-color: transparent;
        color: $titlebrown;
    }
}

//-----------------------
//Alineamiento de texto
//-----------------------

.style__align-right {
    text-align: right;
}

.style__align-left {
    text-align: left;
}

//---------------------------------
//Terminación de alineación de texto
//_---------------------------------

.cc__section {
    box-sizing: border-box;
    padding: 0 40px;
    > div {
        padding: 25px 0;
    }
}

.cc__middle {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
}

.loading-bar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 20;
    background-color: $pink;
}

.cc__column {
}

.cc__checkbox {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    cursor: pointer;
    &-outline {
        display: flex;
        background-color: $white;
        border-radius: 100px;
        border: 1px solid $darkgray;
        position: relative;
        box-sizing: border-box;
        height: 22px !important;
        width: 22px !important;
        padding: 0 !important;
        border-radius: 5px;
        justify-content: center;
        align-items: center;
    }

    &-selected {
        display: none;
        border-radius: 5px;
        background-color: transparent !important;
        padding: 4px;
        box-sizing: border-box;
        width: 100%;
        height: 100%;
        &::after {
            position: absolute;
            content: " ";
            width: 8px;
            height: 4px;
            transform: translate(-50%, -50%) rotate(-45deg);
            top: 50%;
            left: 50%;
            border-bottom: 2px solid $pink;
            border-left: 2px solid $pink;
        }
    }

    p {
        margin-left: 10px;
    }

    .cc__checkbox-input {
        position: absolute;
        // opacity: 0;
        &:checked {
            & ~ .cc__checkbox-outline {
                .cc__checkbox-selected {
                    display: flex;
                    background-color: $pink;
                }
            }
        }
    }
}

.hidden {
    position: fixed;
    visibility: hidden;
}

.button__defult {
    background-color: white !important;
    padding: 0 20px;
    border-radius: 50px;
    border: none;
    height: 45px;
    cursor: pointer;
}

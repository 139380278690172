@import "../../styles.scss";

.cc-styled_no_container {
  width: 100%;
  position: relative;
  padding: 0;
  margin: 0;
  //top: -120px;

  .cc-styled_bar {
    position: absolute;
    top: 0;
    z-index: -1;
    height: 100%;
    background-color: #eb1a71;
    width: 40%;
  }

  .cc-styled-left {
    left: 0;
    border-radius: 0 7px 7px 0;
  }

  .cc-styled-right {
    right: 0;
    border-radius: 7px 0 0 7px;
  }

  .cc_no_parent_styled_wrapper {
    justify-content: center;
    background-color: $lowgray;
    display: flex;
    border-radius: 7px;
  }

  .cc-styled_wrapper {
    justify-content: center;
    background-color: $lowgray;
    display: flex;
    border-radius: 7px;
  }

  .cc-left {
    padding-right: 5%;
    padding-top: 100px;
    padding-bottom: 100px;
    margin-left: 5%;
  }

  .cc-no-container {
    margin: 0 auto;
    box-sizing: border-box;
    padding: 0px;
    width: 70%;
    max-width: 1000px;

    @media only screen and (max-width: 645px) {
      width: 100%;
      padding: 0px;

      h1 {
        margin: 40px !important;
      }
    }

    @media only screen and (min-width: 645px) and (max-width: 1250px) {
      width: 100%;
    }
  }

  .cc-middle {
    margin: 0 auto;
    box-sizing: border-box;
    padding: 100px;
    width: 70%;
    max-width: 1000px;

    @media only screen and (max-width: 645px) {
      width: 100%;
      padding: 0px;

      h1 {
        margin: 40px !important;
      }
    }

    @media only screen and (min-width: 645px) and (max-width: 1250px) {
      width: 100%;
    }
  }

  @media only screen and (max-width: 645px) {
    box-sizing: border-box;
    margin: 0 auto;
  }
}

.cc-styled_container {
  width: 100%;
  position: relative;
  padding: 100px 0;
  margin: 100px 0 50px 0;

  .cc-styled_bar {
    position: absolute;
    top: 0;
    z-index: -1;
    height: 100%;
    background-color: #eb1a71;
    width: 40%;
  }

  .cc-styled-left {
    left: 0;
    border-radius: 0 7px 7px 0;
  }

  .cc-styled-right {
    right: 0;
    border-radius: 7px 0 0 7px;
  }

  .cc-styled_wrapper {
    justify-content: center;
    background-color: $lowgray;
    display: flex;
    border-radius: 7px;
  }

  .cc-left {
    padding-right: 5%;
    padding-top: 100px;
    padding-bottom: 100px;
    margin-left: 5%;
  }

  .cc-no-container {
    margin: 0 auto;
    box-sizing: border-box;
    padding: 100px;
    width: 70%;
    max-width: 1000px;

    @media only screen and (max-width: 645px) {
      width: 100%;
      padding: 0px;

      h1 {
        margin: 40px !important;
      }
    }

    @media only screen and (min-width: 645px) and (max-width: 1250px) {
      width: 100%;
    }
  }

  .cc-middle {
    margin: 0 auto;
    box-sizing: border-box;
    padding: 100px;
    width: 70%;
    max-width: 1000px;

    @media only screen and (max-width: 645px) {
      width: 100%;
      padding: 0px;

      h1 {
        margin: 40px !important;
      }
    }

    @media only screen and (min-width: 645px) and (max-width: 1250px) {
      width: 100%;
    }
  }

  @media only screen and (max-width: 645px) {
    box-sizing: border-box;
    margin: 0 auto;
  }
}

@import "../../adstyle.scss";

.cc__admin-input {
    border-radius: 33px;
    outline: none;
    border: 1px solid $white;
    padding: 13px;
    background-color: $white;
    display: inline-block;
    font: $poppins-14px-bold;
    color: $textgray;
    &:focus {
        border: 1px solid $pink;
    }
}

.cc__input,
.cc__select,
.cc__textarea {
    > textarea {
        height: 120px;
        resize: vertical;
    }
    display: flex;
    flex-direction: column;
    border-radius: 33px;
    > label {
        font: $label-font-poppins-bold;
        color: $pink;
        text-align: left;
        margin-left: 10px;
        margin-bottom: 10px;
    }
    &-error {
        border: 1px solid red;
        position: relative;
        &::after {
            content: " ";
            width: 16px;
            height: 2px;
            border-radius: 100px;
            background-color: red;
            position: absolute;
            top: 50%;
            transform: translateY(-50%) rotate(45deg);
            right: 20px;
        }
        &::before {
            content: " ";
            width: 16px;
            height: 2px;
            border-radius: 10px;
            background-color: red;
            position: absolute;
            top: 50%;
            transform: translateY(-50%) rotate(-45deg);
            right: 20px;
        }
    }

    &-succes {
        border: 1px solid green;
        position: relative;
        &::after {
            content: " ";
            width: 10px;
            height: 4px;
            border-bottom: 2px solid green;
            border-left: 2px solid green;
            border-top: 2px solid transparent;
            border-right: 2px solid transparent;
            position: absolute;
            top: 50%;
            right: 20px;
            transform: translateY(-50%) rotate(-45deg);
        }
    }

    &-warning {
        border: 1px solid yellow;
    }
}

.cc__ {
    &select {
        position: relative;
        width: 100%;
        box-sizing: border-box;
        > select::-ms-expand {
            display: none;
        }
        > select {
            /* for Firefox */
            -moz-appearance: none;
            /* for Chrome */
            -webkit-appearance: none;
        }
        &::after {
            position: absolute;
            top: 30%;
            // transform: translateX(-25%);
            right: 20px;
            content: " ";
            width: 10px;
            height: 10px;
            border-bottom: 2px solid $pink;
            border-right: 2px solid $pink;
            border-left: 2px solid transparent;
            border-top: 2px solid transparent;
            transform: rotate(45deg);
        }
    }
    &input {
        width: 100%;
        box-sizing: border-box;
    }
    &textarea {
        width: 100%;
        box-sizing: border-box;
    }
}

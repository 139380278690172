//Variables

//Program
$program_info-data-p-color: #a0a0a0;

button {
    font-weight: bold;
}

.form__label {
    font-weight: bold;
}


/* @media screen and (min-width: 320px) and (max-width: 767px) and (orientation: landscape) {
    html {
        transform: rotate(-90deg);
        transform-origin: left top;
        width: 100vh;
        overflow-x: hidden;
        position: absolute;
        top: 100%;
        left: 0;
    }
} */
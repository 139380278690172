
//.card__content__card__media__container {
//  background-color: rgba(0,0,0,0.5);
//  position: absolute;
//  bottom: 0;
//  margin: auto;
//  height: 30%;
//  width: 73.5%;
//  border-radius: 5px;
//  display: flex;
//  justify-content: center;
//  align-items: center;
//
//  .card__content__card__media {
//    margin: auto;
//    color: white;
//    font-family: goudy-old-style, serif;
//    font-size: 18px;
//    font-weight: 400;
//  }
//}

.card__biggest {
  background-color: red;
  .card__content__card__media__container {
    background-color: rgba(0,0,0,0.5);
    bottom: 0;
    margin: auto;
    height: 30%;
    width: 73.5%;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;

    .card__content__card__media {
      margin: auto;
      color: white;
      font-family: Poppins, goudy-old-style, serif;
      font-size: 18px;
      font-weight: 400;
      margin: auto;
    }
  }
}


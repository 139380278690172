@import "../../adstyle.scss";

.cc__sidebar {
    // flex-basis: 300px;
    flex-basis: 300px;
    width: 300px;
    &-container {
        border-radius: 20px;
        justify-content: flex-start;
        background-color: $containercolor;
        padding: 10px;
        box-sizing: border-box;
    }

    h1 {
        // margin-top: 20px;
        padding: 0 7px;
        &:not(:first-of-type) {
            padding-top: 36px;
        }
    }

    h3{
        text-align: left;
        margin: 15px;
    }
}

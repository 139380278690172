.sliderbanne__container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 5em 0px 5em 0px;
  /* style={{ minHeight: this.props.heigh ? this.props.heigh : " 40vh" }} */
  height: 40vh;

  // min-height: 650px;
  @media only screen and (max-width: 645px) {
    height: 100vh !important;
  }

  // overflow: hidden;
  position: relative;
  z-index: -1;

  > img {
    position: absolute;
    top: 0;
    left: 0;
    min-width: 100%;
    min-height: 100%;
  }

  h1 {
    /* position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); */
    margin: 3em auto 0.4em;
    font-size: 90px;
    text-align: center !important;
    width: 50%;
    @media only screen and(max-width: 640px) {
      max-width: 100%;
    }
  }
}

@media only screen and (min-width: 645px) and (max-width: 1250px) {
  .sliderbanne__container {
    height: 30vh;

    h1,
    .title-h1-brown {
      margin-top: 120px;
      font-size: 40px;
      width: 87%;
    }
  }
}

@media only screen and (max-width: 644px) {
  .sliderbanne__container {
    // display: none;
    height: 40vh !important;
    display: flex;
    justify-content: center;
    align-items: center;

    > img {
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }

    > h1 {
      font-size: 68px;
      margin: 0 !important;
      margin-top: 2em !important;
    }
  }
}

@import "../../styles.scss";

.thanks__container {
    .sliderbanne__container {
        background: url("../../images/header/header_contact.png") no-repeat;
        background-size: cover;
    }
    // display: flex;
    // flex-direction: column;
    // justify-content: center;
    // align-items: center;

    .thanks__text {
        padding: 150px 0 50px 0;
    }

}

.home {
    overflow: hidden;

    .sliderbanne__container {
        // background: url("../images/header/slider.png") no-repeat;
        background-size: cover;
        background-position: 0% 0%;
        width: 100%;
        height: 60vh;

        .title-h1-brown {
            color: #fff !important;
            margin: 0;
            text-shadow: 0px 3px 15px rgba(0, 0, 0, 0.3);
        }
    }

    @media only screen and (min-width: 645px) and (max-width: 1250px) {
        .sliderbanne__container {
            max-height: 50vh;

            .title-h1-brown {
                min-width: 650px;
                font-size: 68px;
            }
        }
    }

    @media only screen and (max-width: 644px) {
        .sliderbanne__container {
            max-height: 60vh;

            .title-h1-brown {
                min-width: 320px;
                font-size: 60px !important;
            }
        }

        .searchbar_container {
            display: none;
        }

        .home__message {
            width: 90%;

            .parallax-inner {
                transform: none !important;
            }

            h1,
            .title-h1-brown {
                font-size: 24px !important;
                color: #655042;
                padding: 1.5em 0;

                img {
                    // display: none;
                }
            }
        }

        .current_projects-wrapper {
            h1,
            .title-h1-brown {
                u {
                    display: table;
                }
            }
        }

        .ventajas__container {
            margin: 0;

            .title-h1-brown {
                margin: 1em 40px 3em !important;

                u {
                    display: inline-block;
                }
            }

            .ventajas__items-container {
                .ventajas__item-container {
                    margin-bottom: 2em;

                    h2 {
                        font-size: 30px;
                        margin-bottom: 0em;
                    }

                    p {
                        margin-top: 0.5em;
                    }
                }
            }
        }

        .cc-styled_container {
            margin-top: 50px;
        }

        .experience__container {
            .parallax-inner {
                transform: none !important;
            }

            .title-h1-brown {
                margin: 20px 20px 60px !important;
            }

            .small-span {
                font-weight: bold;
            }
        }
    }
}

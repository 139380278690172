@import "../../styles.scss";

.footer__container {
  margin-top: 100px;
  width: 100%;
  background-color: #eeeceb;

  .footer__info-container {
    padding: 50px 150px;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    @media only screen and (min-width: 645px) and (max-width: 1250px) {
      justify-content: center;
    }

    >.left {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      min-width: auto;
      width: 25%;

      @media only screen and (min-width: 645px) and (max-width: 1250px) {
        align-items: flex-start !important;
        min-width: 340px;
      }

      >p {
        text-align: left;
      }

      >form {
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        justify-content: center;
        // width: 100%;

        .form__group {
          position: relative;
          padding: 15px 0 0;
          margin-top: 10px;
          
          .form__field {
            width: 200px;
            font-family: inherit;
            border: 0;
            border-bottom: 1px solid gray;
            outline: 0;
            font-size: 14px;
            color: white;
            padding: 7px 0;
            background: transparent;
            transition: border-color 0.2s;
            color: black;
            font-family: "HelveticaNeueCyr";
            font-size: 16px;

            &::placeholder {
              color: transparent;
            }

            &:placeholder-shown~.form__label {
              font-size: 14px;
              font-family: "HelveticaNeueCyr";
              cursor: text;
              top: 20px;
            }

            &:focus {
              ~.form__label {
                position: absolute;
                top: 0;
                display: block;
                transition: 0.2s;
                font-size: 1rem;
                color: $violeta;
                font-weight: 700;
              }

              padding-bottom: 6px;
              font-weight: 700;
              border-bottom: 1px solid $violeta;
            }

            &:required,
            &:invalid {
              box-shadow: none;
            }
          }

          .form__label {
            position: absolute;
            top: 0;
            display: block;
            transition: 0.2s;
            font-size: 1rem;
            color: gray;
          }
        }

        .submit_form {
          margin-left: 3em;
          outline: none;
          cursor: pointer;
          border-radius: 50px;
          border: 1px solid #eb1a71;
          background-color: #eb1a71;
          color: white;
          padding: 15px 40px;
          margin-top: 20px;
          align-self: flex-end;
          transition: color 0.3s, transform 0.3s;
          box-shadow: 0 3px 10px hsla(330, 100%, 27%, 0.42);
          font-weight: bold;
          font-size: 10px;

          &:hover {
            transform: scale(1.1);

            background-color: white;
            color: #eb1a71;
            border: 1px solid #eb1a71;
          }
        }
      }
    }

    >.right {
      display: flex;
      flex-direction: row;
      min-width: 50%;
      justify-content: space-between;

      @media only screen and (min-width: 645px) and (max-width: 1250px) {
        min-width: 40%;

        //display: none;
        .row {
          padding-right: 0 !important;

          &:last-child {
            display: none;
          }
        }
      }

      .row {
        display: flex;
        padding: 0px 20px;
        flex-direction: column;

        p,
        a {
          font-family: "HelveticaNeueBold" !important;
          text-align: left;
          margin: 10px 0;
          font-size: 10px !important;
          color: $smoothbrown;
        }

        align-content: flex-start;

        .social {
          display: flex;
          margin-top: 0;
          padding: 0;
          flex-direction: row;
          justify-content: flex-start;
          align-items: flex-start;
          align-content: flex-start;
          justify-items: self-start;

          img {
            margin-top: 0;
            padding-top: 0;
          }
        }
      }
    }
  }

  .footer__info-cr {
    background-color: #eb1a71;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 10px 150px;

    @media only screen and (max-width: 645px) {
      padding: 0;
      justify-content: center;
    }

    >p {
      color: white;
    }

    .redes-sociales {
      a {
        img {
          padding: 10px;
          transition: transform 0.3s;

          &:hover {
            transform: scale(1.2);
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 645px) and (max-width: 1250px) {
  .footer__info-container {
    padding: 50px 5% !important;
    justify-content: center !important;
  }

  .footer__info-cr {
    padding: 10px 5% !important;
    justify-content: center !important;
  }
}

@media only screen and (max-width: 644px) {
  .footer__info-container {
    padding: 10px !important;
    display: flex;
    flex-direction: column !important;

    .right {
      display: flex;
      flex-direction: column !important;
      width: 100%;
      align-items: center !important;

      //display: none !important;
      .row {
        padding: 0 !important;
        text-align: center;
        align-items: center;

        &:first-child {
          p {
            &:first-child {
              font-size: 20px;
            }
          }
        }

        &:last-child {
          display: none;
        }
      }
    }

    .left {
      //margin: 10px;
      justify-content: center;
      align-items: center !important;
      width: 100% !important;

      form {
        display: flex;
        flex-direction: column !important;
        align-items: center !important;

        .form__field {
          margin-bottom: 0.8em;
        }

        .submit_form {
          margin: 1em auto !important;
          font-size: 10px;
        }
      }
    }
  }

  .footer__info-container {
    padding: 50px 5% !important;
    justify-content: center !important;
  }

  .footer__info-cr {
    padding: 10px 5% !important;
    justify-content: center !important;
  }
}
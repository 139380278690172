@import "../../styles.scss";

.leyes_container {
  .sliderbanne__container {
    background: url('../../images/header/header_investment.png') no-repeat;
    background-size: cover;
  }

  .table {
    max-width: 650px;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    width: 650px;
    overflow: hidden;
    box-shadow: 0px 15px 20px 5px rgba(0, 0, 0, 0.2);
    margin-top: 5em;

    .header {
      display: flex;
      background-color: $violeta;
      color: $white;
      font-family: "Poppins";
      font-size: 15px;
      font-weight: bold;
      justify-content: space-between;
      padding: 1em 6em;
      text-transform: uppercase;
    }

    .spacer {
      background-color: white;
      height: 2.5em;
    }

    .body {
      display: flex;
      flex-direction: column;


      .row {
        display: flex;
        justify-content: space-between;
        padding: 0.5em 6em;
        font-family: "Poppins";
        font-size: 15px;
        color: #A0A0A0;

        &:nth-child(even) {
          background-color: white;
        }

        &:nth-child(odd) {
          background-color: #EEECEB;
        }

        &:last-child {
          padding-bottom: 1.5em;
        }
      }
    }
  }


}

@media only screen and (max-width: 644px) {
  .leyes_container {

    .cc-styled_wrapper {
      h1 {
        margin: 25px !important;
      }

      p {
        padding: 0 2em !important;
      }

      form {
        margin: 60px auto;
        display: flex;
        justify-content: center;
      }
    }

    .table {
      width: 300px;

      .header {
        padding: 1em 1.2em;
      }

      .body {
        .row {
          padding: 0.5em 1.2em;
        }
      }
    }


  }

}


form {
  margin-top: 60px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;

  button {
    background-color: $violeta;
    border: none;
    color: white;
    padding: 15px 30px;
    border-radius: 100px;

    transition: color 0.3s, transform 0.3s;
    box-shadow: 0 3px 10px hsla(330, 100%, 27%, 0.42);
    border: 1px solid #eb1a71;

    &:hover {
      transform: scale(1.1);

      background-color: white;
      color: #eb1a71;

      &::after {
        border-left-color: $violeta;
        border-bottom-color: $violeta;
      }
    }

    &::after {
      content: "";
      display: inline-block;
      margin-left: 15px;
      width: 5px;
      height: 5px;
      border: 20px solid transparent;
      vertical-align: middle;
      transform: rotate(-135deg);
      border-left: 2px solid $white;
      border-bottom: 2px solid $white;
      border-top: 2px solid transparent;
      border-right: 2px solid transparent;
      z-index: 3;
    }
  }
}
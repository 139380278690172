@import "../../styles.scss";

.inversion__info {
  p {
    text-align: left;
  }

  > h1 {
    margin-top: 0;
    // margin-bottom: 20px;
    @media only screen and (max-width: 645px) {
      padding: 0 !important;
      margin: 0px !important;
    }
  }
}

.inversion__container {
  .sliderbanne__container {
    background: url("../../images/header/header_investment.png") no-repeat;
    background-size: unset;
  }

  .title-h1-brown {
    opacity: 1;
  }

  .cc-styled_container {
    margin-top: 50px;
  }

  .program__map {
    .program__ley-wrapper {
      .map_container {
        filter: none !important;
      }
    }
  }
}

.program__map {
  width: 100%;
  position: relative;
  box-sizing: border-box;
  padding: 70px 0;
  margin-top: 200px;

  .cc_styled-bar {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 67%;
    background-color: $lowgraya;
    z-index: -1;
    border-radius: 7px 0 0 7px;

    @media only screen and (max-width: 645px) {
      width: 100% !important;
    }
  }

  .program__ley-wrapper {
    padding: 20px;
    box-sizing: border-box;
    position: relative;
    display: flex;
    flex-direction: row;
    margin: 0 auto;
    justify-content: space-between;
    align-items: flex-start;
    width: 90%;
    margin: 0 auto;
    max-width: 1100px;

    @media only screen and (min-width: 641px) and(max-width: 1007px) {
      flex-direction: column;
      width: 80% !important;
      padding: 20px;
    }
    @media only screen and (max-width: 641px) {
      flex-direction: column;
      width: 90%;
    }

    .map_container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      @media only screen and(min-width: 1007px) {
        width: 444px;
      }
      @media only screen and (min-width: 641px) and(max-width: 1007px) {
        max-width: 444px;
      }
    }
    .info_venta {
      display: flex;
      flex-direction: column;
      box-sizing: border-box;
      justify-content: flex-start;
      max-width: 100%;
      margin-left: 60px;
      p {
        color: $normalgray;
        margin-top: 40px;
      }
      h1 {
        text-align: left;
        margin: 0;

        u {
          display: table-cell;
        }
      }

      @media only screen and (min-width: 641px) and(max-width: 1007px) {
        width: 100% !important;
        width: 100%;
        padding: 20px;
        h1 {
          padding: 0;
          margin: 0;
          margin-top: 20px;
        }
        p {
          margin-top: 30px;
        }
      }
      @media only screen and (max-width: 641px) {
        margin-top: 50px;
        padding: 10px;
        .title-h1-brown {
          padding: 0 !important;
          margin: 0 !important;
        }
      }

      p {
        text-align: left;
      }

      .contact_data-container {
        margin-top: 10px;

        .contact_data-item {
          display: flex;
          flex-direction: row;
          margin: 0 30px;
          margin-bottom: 20px;

          .contanct_data-info {
            display: flex;
            flex-direction: column;
            margin-left: 20px;
            justify-content: center;
            align-items: flex-start;

            a,
            p {
              margin: 2px;
              padding: 0;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 644px) {
  .inversion__container {
    width: 100%;
    overflow: hidden !important;
    max-width: 100vw !important;

    .sliderbanne__container {
      h1 {
        max-width: unset;
      }
    }

    .inversion__info {
      padding: 15px !important;

      h1 {
        font-size: 40px !important;
        padding: 1em 0.2em !important;
        margin: 0 !important;
      }

      p {
        text-align: center;
      }
    }

    .program__map {
      .program__ley-wrapper {
        .info_venta {
          margin-left: 0 !important;

          h1 {
            padding: 0;
            margin: 0;
            font-size: 40px;
          }

          p {
            text-align: center;
            margin-top: 3em;
          }
        }
      }
    }
  }
}

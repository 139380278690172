@import "../../styles.scss";

.contact__container {
  .sliderbanne__container {
    background: url("../../images/header/header_contact.png") no-repeat;
    background-size: cover;
  }

  .contact__wrapper {
    display: flex;
    flex-direction: row;
    // justify-content: center;
    // align-items: center;
    position: relative;
    margin: 200px auto;
    padding: 50px 0;
    // max-width: 1200px;

    @media only screen and (max-width: 820px) {
      flex-direction: column;
    }

    .styled-bg {
      position: absolute;
      background-color: $lowgraya;
      top: 0;
      left: 0;
      width: 80%;
      height: 100%;
      border-radius: 7px;
      z-index: -1;

      @media only screen and (max-width: 820px) {
        width: 100%;
        height: 65%;
      }
    }

    .form__container {
      box-sizing: border-box;
      // width: 100%;
      border-radius: 7px;
      padding: 10px 50px;
      margin-top: 100px;
      margin: 0 auto;
      width: 40%;

      //   background-color: $lowgraya;
      // margin: 50px 150px;
      @media only screen and (min-width: 645px) and (max-width: 1250px) {
        width: 90% !important;
      }

      @media only screen and (max-width: 645px) {
        margin-bottom: 200px;
      }

      > form {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        label {
          width: 100%;
          text-align: left;
          margin-top: 20px;

          p {
            color: "#5c5c5c" !important;
          }

          input[type="text"],
          input[type="number"],
          textarea {
            box-sizing: border-box;
            width: 100%;
            padding: 10px;
            resize: none;
            border-radius: 7px;
            border: 1px solid #eeeceb;
          }
          .input__correct {
            position: relative;
            border-radius: 7px;
            > input {
              border: 1px solid #81d100 !important;
            }

            &::after {
              transform: rotate(-45deg);
              content: "";
              position: absolute;
              top: 14px;
              right: 10px;
              width: 10px;
              height: 5px;
              border-bottom: 2px solid #81d100;
              border-left: 2px solid #81d100;
              border-top: 0 solid transparent;
              border-right: 0 solid transparent;
            }
          }
          .input__incorrect {
            position: relative;
            border-radius: 7px;
            > input {
              border: 1px solid #ec5f5f !important;
            }

            &::after {
              transform: rotate(45deg);
              content: "+";
              font-weight: 900;
              font-size: 20px;
              position: absolute;
              top: 6px;
              color: #ec5f5f;
              right: 10px;
              width: 10px;
              height: 5px;
            }
          }

          textarea {
            height: 295px;
          }
        }

        .form__button {
          display: flex;
          flex-direction: row;
          width: 100%;
          justify-content: flex-end;
          margin-top: 2em;

          .cancel {
            margin-left: 30px;
            outline: none;
            cursor: pointer;
            border-radius: 50px;

            background-color: white;
            color: #eb1a71;
            border: 1px solid #eb1a71;

            padding: 15px 40px;
            margin-top: 20px;
            align-self: flex-end;
            transition: color 0.3s, transform 0.3s;
            box-shadow: 0 3px 10px hsla(330, 100%, 27%, 0.42);

            &:hover {
              transform: scale(1.1);
              background-color: #eb1a71;
              color: white;
            }
          }

          .send {
            margin-left: 30px;
            outline: none;
            cursor: pointer;
            border-radius: 50px;
            background-color: #eb1a71;
            color: white;
            padding: 15px 40px;

            border: 1px solid #eb1a71;
            margin-top: 20px;
            align-self: flex-end;
            transition: color 0.3s, transform 0.3s;
            box-shadow: 0 3px 10px hsla(330, 100%, 27%, 0.42);

            &:hover {
              transform: scale(1.1);

              background-color: white;
              color: #eb1a71;
              border: 1px solid #eb1a71;
            }
          }
        }
      }
    }

    .map_container {
      width: 50%;

      @media only screen and (min-width: 645px) and (max-width: 1250px) {
        margin-top: 150px;
        width: 90%;
      }

      iframe {
        width: 100% !important;
      }

      .contact_data-container {
        margin-top: 35px !important;
        @media only screen and(min-width:641px) and (max-width: 1007px) {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
        }

        @media only screen and (max-width: 820px) {
          margin-top: 50px;
        }

        .contact_data-item {
          display: flex;
          flex-direction: row;
          margin: 0 30px;
          margin-bottom: 20px;

          .contanct_data-info {
            display: flex;
            flex-direction: column;
            margin-left: 20px;
            justify-content: center;
            align-items: flex-start;

            a,
            p {
              margin: 2px;
              padding: 0;
              font-family: "Poppins";
              font-size: 14px;
            }
          }
        }
      }
    }

    .social {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-top: 20px;
      margin-left: 20px !important;
    }
  }
}
.addres-customwith {
  text-align: left;
  width: 200px;
}

@media only screen and (max-width: 644px) {
  .contact__wrapper {
    margin: 200px auto 0px !important;
    margin-top: 3em !important;

    .form__container {
      width: unset !important;
      padding: 0 !important;
      margin-bottom: 60px !important;

      h1 {
        margin: 0.5em 25px 1em !important;
        font-size: 40px;
      }

      p {
        margin: 1em 25px;
      }

      form {
        margin: 1em 25px !important;

        .form__button {
          justify-content: space-around !important;
        }

        .cancel,
        .send {
          margin-left: 0 !important;
        }
      }
    }

    .contact_data-container {
      .contact_data-item {
        .contanct_data-info {
          p {
            text-align: left;
          }
        }
      }
    }

    .map_container {
      width: 80vw !important;
      //height: 500px !important;
      overflow: hidden !important;
    }
  }
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

$gray: rgba(102, 81, 66, 0.4);
$violeta: #eb0271;
$smoothbrown: #655042;
$normalgray: #5c5c5c;
$white: white;

@font-face {
  font-family: "HelveticaNeueCyr";
  src: url("./fonts/HelveticaNeue-Medium.ttf");
  src: local("./fonts/HelveticaNeue-Medium"), url("./fonts/HelveticaNeue-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "HelveticaNeueBold";
  src: url("./fonts/Helvetica Neu Bold.ttf");
  src: local("./fonts/Helvetica Neu Bold"), url("./fonts/Helvetica Neu Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}

.title-h1-white {
  font-family: Poppins, goudy-old-style, serif;
  font-style: normal;
  font-weight: 400;
  color: $white;
  font-size: 82px;
  text-shadow: 2px 2px 6px rgba(0, 0, 0, 0.415);
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.title-h1-brown {
  font-family: Poppins, goudy-old-style, serif;
  font-style: normal;
  font-weight: 400;
  color: $smoothbrown;
  font-size: 64px;

  // line-height: 55px;
  @media only screen and (max-width: 644px) {
    font-size: 44px !important;
    padding: 0;
    margin: 20px 60px 60px !important;
  }
}

.subtitle-h1-like-brown {
  font-family: Poppins, goudy-old-style, serif;
  font-style: normal;
  font-weight: 400;
  color: $smoothbrown;
  text-align: left;
  font-size: 64px;

  // line-height: 55px;
  @media only screen and (max-width: 644px) {
    font-size: 44px !important;
    padding: 0;
    margin: 20px 60px 60px !important;
  }
}

.title-h1-pink {
  font-family: Poppins, goudy-old-style, serif;
  font-style: normal;
  font-weight: 400;
  color: $violeta !important;
  font-size: 64px;

  @media only screen and (max-width: 644px) {
    font-size: 24px !important;
    padding: 0;
  }
}

.title-h1-gray {
  font-family: Poppins, goudy-old-style, serif;
  font-style: normal;
  font-weight: 400;
  color: $gray !important;
  font-size: 64px;

  @media only screen and (max-width: 644px) {
    font-size: 24px !important;
    padding: 0;
  }
}

.title-h2-brown {
  font-family: Poppins, goudy-old-style, serif;
  font-style: normal;
  font-weight: 400;
  color: $smoothbrown !important;
  font-size: 24px;
}

.title-h2-pink {
  font-family: Poppins, goudy-old-style, serif;
  font-style: normal;
  font-weight: 400;
  color: $violeta !important;
  font-size: 24px;
}
.title-h2-pink-helvetica {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  color: $smoothbrown !important;
  font-size: 24px;
  display: block;
  font-size: 1.5em;
  margin-top: 0.83em;
  margin-bottom: 0.83em;
  margin-left: 0;
  margin-right: 0;
  font-weight: bold;
}

.title-h2-pink-i {
  font-family: Poppins, goudy-old-style, serif;
  font-style: italic;
  font-weight: 400;
  color: $violeta !important;
  font-size: 24px;
}

.subtitle-h2-like-pink-i {
  font-family: Poppins, goudy-old-style, serif;
  font-style: italic;
  text-align: left;
  font-weight: 400;
  color: $violeta !important;
  font-size: 24px;
}

.title-h2-brown-i {
  font-family: Poppins, goudy-old-style, serif;
  font-style: italic;
  font-weight: 400;
  color: $smoothbrown !important;
  font-size: 24px;
}

p {
  font-family: "Poppins";
  color: $normalgray;
  font-size: 14px;
}

button,
input,
textarea {
  -webkit-appearance: none !important;
  appearance: none;
}
input[type="checkbox"] {
  -webkit-appearance: checkbox !important;
}

.big-violet-p {
  font-size: 16px;
  color: $violeta;
  font-weight: 700;
}

u {
  text-decoration: none;
  border-bottom: 2px solid $violeta;
}

.decoration-p-brown {
  font-family: Poppins, goudy-old-style, serif;
  font-style: italic;
  font-weight: 400;
  font-size: 20px;
  color: $smoothbrown !important;
}

.left-align {
  text-align: left !important;
}

.right-align {
  text-align: right !important;
}

.center-align {
  text-align: center !important;
}

.small-span {
  font-family: "Poppins";
  color: $normalgray;
  font-size: 13px;
}

.small-span-b {
  font-family: "Poppins";
  color: $normalgray;
  font-size: 13px;
  font-weight: 500;
}

a {
  font-family: Poppins, goudy-old-style, serif;
  font-style: normal;
  font-weight: 400;
  color: $smoothbrown !important;
  font-size: 18px;
  text-decoration: none;
}

body {
  width: 100%;
  overflow-x: hidden;

  @media only screen and (max-width: 600px) {
  }
}

input,
textarea {
  font-family: "Poppins";
  font-size: 14px;
  outline: none;
}

input[type="button"],
input[type="submit"],
button {
  font-family: "Poppins";
  font-weight: 500;
}

.top-margin {
  margin-top: 10px;
}

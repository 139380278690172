@import "../../styles.scss";

.venta_container {
  .cc-styled_container {
    margin-top: 50px;
  }
  .sliderbanne__container {
    background: url("../../images/header/header_sell.png") no-repeat;
    background-size: cover;
  }

  p {
    color: $normalgray !important;
  }
}

.inversion__info {
  h2 {
    font-size: 64px !important;
  }

  p,
  a {
    color: $normalgray !important;
    font-family: 'Poppins';
    font-size: 14px;
    text-align: left;
  }
  h1 {
    text-align: left;
  }

  h2 {
    font-family: 'Poppins';
    font-size: 24px;
  }
  p {

  }
}

.program__map {
  width: 100%;
  position: relative;
  box-sizing: border-box;
  padding: 70px 0;
  margin-top: 200px;

  .cc_styled-bar {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 67%;
    background-color: $lowgraya;
    z-index: -1;
    border-radius: 7px 0 0 7px;
    @media only screen and (min-width: 641px) and(max-width: 1007px) {
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
    }
  }

  .program__venta-wrapper {
    padding: 20px;
    box-sizing: border-box;
    position: relative;
    display: flex;
    flex-direction: row;
    margin: 0 auto;
    justify-content: space-between;
    align-items: flex-start;
    width: 70%;
    margin: 0 auto;
    max-width: 1100px;

    @media only screen and (min-width: 641px) and(max-width: 1007px) {
      flex-direction: column;
      padding: 20px;
      justify-content: center !important;
      .map_container {
        padding: 20px;
      }
    }
    @media only screen and (max-width: 641px) {
      flex-direction: column;
      width: 90%;
    }

    .map_container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      max-width: 444px;
      .dropdown {
        .info a {
          color: $violeta !important;
        }
      }
    }
    .info_venta {
      display: flex;
      flex-direction: column;
      box-sizing: border-box;
      justify-content: flex-start;
      max-width: 500px;
      margin-left: 60px;

      h2 {
        text-align: left;
        margin: 0;
        font-size: 64px;

        u {
          display: table-cell;
        }
      }

      @media only screen and (min-width: 641px) and(max-width: 1007px) {
        margin: 0 auto;
        width: 100% !important;
        max-width: 100% !important;
        padding: 20px;
        h1 {
          padding: 0;
          margin: 0;
          margin-top: 20px;
        }
        p {
          margin-top: 30px;
        }
      }
      @media only screen and (max-width: 641px) {
        margin-top: 50px;
        padding: 10px;
        .title-h1-brown {
          padding: 0 !important;
          margin: 0 !important;
        }
      }

      p {
        text-align: left;
      }

      .contact_data-container {
        margin-top: 10px;

        .contact_data-item {
          display: flex;
          flex-direction: row;
          margin: 0 30px;
          margin-bottom: 20px;

          .contanct_data-info {
            display: flex;
            flex-direction: column;
            margin-left: 20px;
            justify-content: center;
            align-items: flex-start;

            a,
            p {
              margin: 2px;
              padding: 0;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 644px) {
  .venta_container {
    .inversion__info {
      h2 {
        font-size: 64px !important;
        margin: 40px 25px !important;
      }

      p {
      }
    }

    .program__map {
      .program__venta-wrapper {
        .info_venta {
          margin-left: 0 !important;

          h2 {
            font-size: 40px !important;
          }
        }

        .map_container {
          .dropdown {
            .info a {
              color: $violeta !important;
            }
          }
        }
      }
    }
  }
}

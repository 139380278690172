@import "../../adstyle.scss";

.ley-selected {
    width: 250px;
    height: 140px;
    display: flex;
    flex-direction: row;
    flex-basis: auto !important;
    justify-content: space-between;
    align-items: center;
    border-radius: 20px;
    box-sizing: border-box;
    padding: 20px 20px !important;
    margin: 13px;
    position: relative;
    & * {
        z-index: 2;
    }

    .bg-container {
        border-radius: 20px;
        background-color: rgba(255, 255, 255, 0.5);
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        width: 100%;
        height: 100%;
    }

    .left {
        > .img__ley-icon {
            border: 1px solid $buttongray;
            width: 66px;
            height: 66px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 100px;
            overflow: hidden;
            > img {
                max-width: 100%;
            }
        }
    }
    .shadow {
        position: absolute;
        width: 110%;
        height: 120px;
        bottom: -60px;
        z-index: 0;
        left: 50%;
        transform: translateX(-50%);
        background-image: radial-gradient(rgba(27, 27, 27, 0.102), rgba(140, 140, 140, 0) 70%);
    }
}

@import "../../styles.scss";

.cookie-container {
    position: fixed;
    z-index: 6;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: $lowgray;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    padding: 10px 30px;
    box-shadow: 0 -3px 10px hsla(330, 100%, 27%, 0.42);

    .p-link {
        color: $violeta !important;
        font-size: 14px;
        font-family: "Poppins";
    }

    .cookie__btn-conatiner {
        display: flex;
        flex-direction: row;
        margin-left: 20px;
        .cookie__cancel {
            outline: none;
            cursor: pointer;
            border-radius: 50px;

            background-color: white;
            color: #eb1a71;
            border: 1px solid #eb1a71;
            margin-right: 20px;

            padding: 15px 40px;
            align-self: flex-end;
            transition: color 0.3s, transform 0.3s;
            box-shadow: 0 3px 10px hsla(330, 100%, 27%, 0.42);

            &:hover {
                transform: scale(1.1);
                background-color: #eb1a71;
                color: white;
            }
        }

        .cookie__acept {
            outline: none;
            cursor: pointer;
            border-radius: 50px;
            background-color: #eb1a71;
            color: white;
            padding: 15px 40px;

            border: 1px solid #eb1a71;
            align-self: flex-end;
            transition: color 0.3s, transform 0.3s;
            box-shadow: 0 3px 10px hsla(330, 100%, 27%, 0.42);

            &:hover {
                transform: scale(1.1);

                background-color: white;
                color: #eb1a71;
                border: 1px solid #eb1a71;
            }
        }
    }
}

@media only screen and (max-width: 607px) {
    .cookie-container {
        flex-direction: column;
        padding: 20px;
        
        .cookie__btn-conatiner {
            width: 100%;
            margin-top: 20px;
            justify-content: space-between;
            .cookie__acept,
            .cookie__cancel {
                flex: 1;
            }
        }
    }
}

.card__container-shadow {
  box-shadow: 0 4px 24px rgba(0, 0, 0, 0.26);
}

.card__container {
  flex: 1;
  position: relative;
  min-width: 300px;
  max-width: 310px;
  // height: 225px;
  // max-height: 225px;
  overflow: hidden;
  transition: transform 0.3s;
  margin: 0 20px;
  cursor: auto;
  
  &-p {
    // background-color: white !important;
    margin: 0 10px;
    padding: 10px 0;
    min-width: 300px;
    .card__img {
      flex: 1;
      position: relative;
      max-width: 210px;
      height: 125px;
      max-height: 125px;
      overflow: hidden;
      transition: transform 0.3s;
      margin: 0 20px;
      // cursor: pointer;
    }
  }

  &:hover {
    transform: scale(1.05);
    z-index: 3;
  }

  .card__img {
    height: 100%;
    // max-height: 100%;
  }

  .card__info-container {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    font-family: "Poppins" !important;
    font-weight: bold !important;
    min-height: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &::after {
      position: absolute;
      content: "";
      width: 100%;
      height: 100%;
      background-color: rgba($color: #655042, $alpha: 0.6);
      mix-blend-mode: multiply;
    }

    h3,
    p {
      color: white !important;
      margin: 0.1em auto;
      mix-blend-mode: normal;
      z-index: 3;
      text-align: center;
      font-family: "Poppins";
    }

    h3 {
      text-transform: uppercase;
      font-size: 18px;
      font-family: "Poppins" !important;
      font-weight: bold !important;
    }
  }
}

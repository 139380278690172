@import "../../styles.scss";

.slide_show___container {
  display: flex;
  flex-direction: column;
  //box-sizing: border-box;
  //align-items: flex-end;
  width: 100%;

  .flickity-button {
    display: block !important;
    background-color: hsla(0, 0%, 100%, 0.75);
    margin-top: 10px;
  }
  .flickity-enabled {
    overflow: hidden;
    padding-bottom: 5px;
  }
  .ss__selectedimg {
    background-color: $mediumgray;
    width: 700px;
    height: 500px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
    box-sizing: border-box;
    overflow: hidden;
    .ss__selectedimg-container {
      width: 100%;
      > img {
        cursor: pointer;
        max-width: 100%;
        max-height: 100%;
      }
    }

    @media only screen and (max-width: 740px) {
      width: 100%;
      padding: 0;

      > img {
        max-width: 100%;
        height: auto;
      }
    }
  }

  .ss__carrousel {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: flex-end !important;
    flex-wrap: wrap;

    .ss__image-container {
      display: flex;
      align-items: center;
      background-color: $lowgray;
      width: 100px;
      overflow: hidden;
      margin-top: 20px;
      margin-left: 20px;
      height: 100px;
      cursor: pointer;
      transition: transform 0.2s;
      position: relative;
      > img {
        max-height: 100%;
      }
      .video-icon {
        position: absolute;
        top: 50%;
        left: 50%;
        //transform: translate(-50%, -50%);
      }
      a {
        width: 100%;
        height: 100%;
        .vid {
          width: 100%;
          height: 100%;
        }
        img {
          height: 100% !important;
          filter: brightness(0.4) !important;
        }
      }

      .vid {
        //filter: brightness(0.4) !important;
      }

      &:hover {
        transform: scale(1.1);
      }
    }
  }
}

.slide_show___container_modal {
  position: fixed;
  top: 0;
  left: 0;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  display: none;
  backdrop-filter: blur(10px);
  //------------------------------------
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  z-index: 20 !important;

  .flickity-button {
    display: block !important;
  }

  .ss__selectedimg {
    flex: 2;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    height: auto;
    .ss__selectedimg-container {
      position: relative;
      > img {
        max-width: 900px;
        width: 100%;
        max-height: 500px;
      }
    }
  }
  .flickity-prev-next-button {
    background-color: rgba(255, 255, 255, 0.5);
  }
  .flickity-viewport {
    margin-bottom: 20px;
  }
  .ss__carrousel {
    display: flex;
    flex-direction: row;
    width: 60%;
    justify-content: flex-end !important;
    // background-color: white;

    @media only screen and (max-width: 740px) {
      width: 100%;
      padding: 0;
    }
    .ss__image-container {
      display: flex;
      align-items: center;
      overflow: hidden;
      background-color: $lowgray;
      width: 100px;
      margin-top: 20px;
      margin-left: 20px;
      height: 100px;
      cursor: pointer;
      transition: transform 0.2s;
      position: relative;
      > img {
        max-height: 100%;
      }
      .video-icon {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
      a {
        width: 100%;
        height: 100%;
        .vid {
          width: 100%;
          height: 100%;
        }
        img {
          height: 100% !important;
          filter: brightness(0.4) !important;
        }
      }

      .vid {
        filter: brightness(0.4) !important;
      }

      &:hover {
        transform: scale(1.1);
      }
    }
  }
}

.close-modal {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 290;
  height: 30px;
  width: 30px;
  cursor: pointer;
  transition: transform 0.3s;
  &:hover {
    transform: scale(1.3);
  }

  &::after {
    position: absolute;
    transform: rotate(45deg);
    top: 20px;
    right: 20px;
    content: " ";
    height: 3px;
    width: 20px;
    border-radius: 10px;
    border: 1px solid $violeta;
    background-color: $violeta;
  }
  &::before {
    content: " ";
    position: absolute;
    transform: rotate(-45deg);
    top: 20px;
    right: 20px;
    height: 3px;
    width: 20px;
    border-radius: 10px;
    background-color: $violeta;
    border: 1px solid $violeta;
  }
}

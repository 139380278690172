@import "../../adstyle.scss";

$containerBG: #e8e8e8;

.StyleEditable {
    display: flex;
    flex-direction: row;
    width: 300px;
    height: 137px;
    justify-content: space-between;
    background-color: #eeeceb89;
    box-sizing: border-box;
    padding: 0 30px !important;
    border-radius: 20px;
    position: relative;
    .cc__styled_editable-shadow {
        position: absolute;
        width: 110%;
        height: 100px;
        bottom: -50px;
        z-index: -2;
        left: 50%;
        transform: translateX(-50%);
        background-image: radial-gradient(rgba(27, 27, 27, 0.102), rgba(140, 140, 140, 0) 70%);
    }
    .cc__styled_editable-bg {
        width: 100%;
        height: 100%;
        border-radius: 20px;
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        opacity: 0.46;
    }
    .cc__styled_editable-inner {
        z-index: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;

        align-items: flex-start;
        &:nth-child(3) {
            align-items: center;
        }
        > p {
            margin: 0;
            font: $label-font-poppins-bold;
            color: $titlebrown;
        }
        .cc__editable-img {
            height: 50px;
        }
        .cc__editable-icon {
            width: 25px;
        }
        > p {
            font: $editable_poppins-18;
        }
    }
}

.cc__styled-checkbox {
    // background-color: red;
    display: block;
    background-color: $white;
    border-radius: 100px;
    border: 1px solid $darkgray;
    position: relative;
    box-sizing: border-box;
    cursor: pointer;
    height: 25px !important;
    width: 25px !important;
    padding: 0 !important;

    &-selected {
        display: none;
        width: 100%;
        height: 100%;
        border-radius: 100px;
        background-color: $pink !important;
        position: relative;
        &::after {
            position: absolute;
            content: " ";
            width: 8px;
            height: 4px;
            transform: translate(-50%, -50%) rotate(-45deg);
            top: 50%;
            left: 50%;
            border-bottom: 2px solid white;
            border-left: 2px solid white;
        }
    }

    &-input {
        cursor: pointer;
        position: absolute;
        opacity: 0;
        &:checked {
            + .cc__styled-checkbox-selected {
                display: flex;
            }
            + .cc__styled-checkbox {
                background-color: $pink !important;
                border: 1px solid $pink !important;
            }
        }
    }
}

@import "../../styles.scss";

.group_container {
  .sliderbanne__container {
    background: url("../../images/header/header_group.png") no-repeat;
    background-size: cover;
  }

  p {
    color: #6d6d6d !important;
  }
  h1,
  p,
  h2 {
    text-align: right;
  }

  h2.title-h2-brown {
    font-size: 64px !important;
  }

  .title-h2-brown {
    font-size: 34px !important;
    font-family: Poppins, goudy-old-style, serif;
    margin: 0.7em 0 0.7em !important;
  }
  .inversion__info-g {
    h1,
    h2,
    p {
      text-align: right !important;
    }
  }
}

.current_projects {
  margin-top: 100px;
  width: 100%;
  box-sizing: border-box;
  position: relative;
  display: flex;
  height: 120vh;
  min-height: 866px;
  justify-content: center;
  align-items: center;

  .current_projects-decorative {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 50%;
    background-color: $lowgraya;
    border-radius: 7px;
  }

  .current_projects-wrapper {
    width: 100%;
    margin: 50px 0;
    padding: 50px 10px;
    background-color: $lowgray;
    border-radius: 7px;
  }
}

@media only screen and (max-width: 644px) {
  .group_container {
    h1 {
      font-size: 40px !important;
      text-align: left;
    }
    p,
    h2 {
      text-align: left;
    }
  }
}
